import { FormControlLabel, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import { Control, Controller, FieldError, Path } from 'react-hook-form'

interface IArtisanForm<T> {
  control: Control<T>
  name: Path<T>
  label?: string
  error?: FieldError
  required?: boolean
}

export default function InputDateControl<T>({
  control,
  name,
  label,
  error,
  required = false,
}: Readonly<IArtisanForm<T>>): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          labelPlacement="top"
          required={required}
          style={{ display: 'flex', flex: '1', alignItems: 'flex-start', textAlign: 'left' }}
          control={
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <DatePicker
                //@ts-expect-error no comment
                value={field.value ? DateTime.fromISO(field.value) : null}
                onChange={(e) => e && field.onChange(e.toISO())}
                slotProps={{
                  field: { clearable: true, onClear: () => field.onChange(null) },
                }}
                format="dd/MM/yyyy"
              />
              {error && <p style={{ color: 'red', fontSize: 10 }}>{error.message}</p>}
            </div>
          }
        />
      )}
    />
  )
}
