import { QuotationClass, IQuotationsGet } from '@Arti-zen/package-backoffice'

export interface IQuotationListState {
  isLoading: boolean
  errorType?: QuotationListStoreErrorType
  isQueriedAllCompleted: boolean
  quotations: QuotationClass['meilisearchDoc'][]
  filters?: IQuotationsGet
}

export enum QuotationListStoreErrorType {
  network = 'network',
  dbLinkedDocument = 'dbLinkedDocument',
  sendByEmail = 'sendByEmail',
}
