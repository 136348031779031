import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.invoiceList.thunks'
import { IInvoiceListState } from './store.invoiceList.interfaces'

const initialState: IInvoiceListState = {
  isLoading: false,
  isQueriedAllCompleted: false,
  invoices: [],
}

const invoiceListSlice = createSlice({
  name: 'invoiceList',
  initialState,
  reducers: {},
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const invoiceListActions = { ...invoiceListSlice.actions, ...thunks.asyncActions }
export default invoiceListSlice.reducer
