import styled from 'styled-components'
import { Theme } from 'theme/theme'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const LeftBar = styled.div`
  display: flex;
  flex-direction: column;
  border-right: solid 1px ${Theme.colors.greyDark};
  margin-top: 15px;
  padding-right: 15px;
  font-size: 15px;
  gap: 20px;
`

export const WrapperFilter = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperCheckBoxes = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid ${Theme.colors.blueArtizen};
  color: ${Theme.colors.blueArtizen};
`

export const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`
