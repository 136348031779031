import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.quotationList.thunks'
import { IQuotationListState } from './store.quotationList.interfaces'

const initialState: IQuotationListState = {
  isLoading: false,
  isQueriedAllCompleted: false,
  quotations: [],
}

const quotationListSlice = createSlice({
  name: 'quotationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const quotationListActions = { ...quotationListSlice.actions, ...thunks.asyncActions }
export default quotationListSlice.reducer
