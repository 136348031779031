import { ReactElement, useEffect, useReducer, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useDebounce } from 'hooks/useDebounce'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Order, IClientsGet, ClientClass } from '@Arti-zen/package-backoffice'
import { IClientList } from '../ClientList.interfaces'
import { clientListActions } from 'modules/clientList/store/store.clientList.reducer'
import { definitionFields, firstColumn } from './ClientListTable.definitionFields'
import { Button } from '@mui/material'

export default function ClientListTable({ search = null, filterBase = {} }: Readonly<IClientList>): ReactElement {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { artisan } = useAppSelector((state) => state.artisan)
  const { clients, isQueriedAllCompleted } = useAppSelector((state) => state.clientList)

  // const [noFilter, setNoFilter] = useState(true)
  const [currentSort, setCurrentSort] = useReducer(sortReducer, [{ key: 'id', order: Order.DESC }])

  const fields = definitionFields()
  const [displayedFields, setDisplayedFields] = useState(fields.map((field) => field.indexName))

  const searchText = useDebounce(search, 500)

  const refresh = () => {
    const newFilters: IClientsGet = {
      userId: artisan?.id,
      page: 1,
      limit: 15,
      ...filterBase,
      searchText: searchText,
      sort: currentSort,
    }

    dispatch(clientListActions.fetch(newFilters))
  }

  useEffect(() => {
    refresh()
  }, [searchText, filterBase, currentSort])

  const nextPage = async () => {
    if (!isQueriedAllCompleted) {
      dispatch(clientListActions.fetchNextPage())
    } else {
      return null
    }
  }

  const getAll = async () => {
    if (!isQueriedAllCompleted) {
      dispatch(clientListActions.fetchAll())
    } else {
      return null
    }
  }

  return (
    artisan && (
      <TableInfinite<ClientClass['meilisearchDoc']>
        definitionFields={fields}
        firstColumn={firstColumn}
        displayedFields={displayedFields}
        setDisplayedFields={setDisplayedFields}
        data={clients}
        nextPage={nextPage}
        isQueriedAllCompleted={isQueriedAllCompleted}
        onSort={(indexName, order) => setCurrentSort({ key: indexName, order })}
        activeSorts={currentSort}
        displayError={null}
        exportFileName={`${artisan.id} - fichier clients`}
        getAll={getAll}
      />
    )
  )
}

const sortReducer = (
  _state,
  action: { key: keyof ClientClass['meilisearchDoc']; order: Order }
): IClientsGet['sort'] => {
  const { key, order } = action

  return [
    { key, order },
    { key: 'id', order: Order.DESC },
  ]
}
