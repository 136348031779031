import { UserArtizen } from '@Arti-zen/package-backoffice'
import { Button } from '@mui/material'
import SelectControl from 'components/forms/Select.control'
import { ReactElement, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import { yupResolver } from '@hookform/resolvers/yup'
import InputDateControl from 'components/forms/InputDate.control'
import { TArtisan } from '../forms/ArtisanForm.interfaces'
import { artisanSchema } from 'modules/artisan/store/store.artisan.interfaces'
import SelectBase from 'components/forms/Select.base'

interface IArtisanForm {
  artisan: UserArtizen
  onSubmit: SubmitHandler<Partial<UserArtizen>>
  isAbo?: boolean
}

export default function ArtisanSubscriptionForm({
  artisan,
  onSubmit,
  isAbo = false,
}: Readonly<IArtisanForm>): ReactElement {
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
  } = useForm<TArtisan>({
    //@ts-expect-error no comment
    defaultValues: { isSelfEmployed: false, ...artisan },
    resolver: yupResolver(artisanSchema),
    reValidateMode: 'onChange',
  })

  const isNoChange = useMemo(() => Object.keys(dirtyFields).length === 0, [dirtyFields])

  const onValidated = (data: UserArtizen) => {
    const changedFieldKey = Object.keys(dirtyFields) as (keyof UserArtizen)[]
    if (changedFieldKey.length > 0) {
      const changes = {}
      changedFieldKey.map((dirtyField) => (changes[dirtyField] = data[dirtyField]))
      onSubmit(changes)
    }
  }

  return (
    <form
      //@ts-expect-error no comment
      onSubmit={handleSubmit(onValidated, console.error)}
      style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 20 }}
    >
      <Title>Abonnement</Title>
      <Row>
        <SelectControl
          control={control}
          name="isSubscribed"
          label="Abonné"
          error={errors.isSubscribed}
          items={[
            { value: 'false', label: 'non' },
            { value: 'true', label: 'oui' },
          ]}
          defaultValue="false"
          required
        />

        <InputDateControl
          control={control}
          name="subscriptionDate"
          label="Date d'inscription"
          error={errors.subscriptionDate}
        />
        <InputDateControl
          control={control}
          name="resiliationDatetime"
          label="Date de résiliation"
          error={errors.resiliationDatetime}
        />
      </Row>
      <Row>
        <SelectControl
          control={control}
          name="demoAccount"
          label="Démo"
          error={errors.demoAccount}
          items={[
            { value: 'false', label: 'non' },
            { value: 'true', label: 'oui' },
          ]}
          defaultValue="false"
          required
        />

        <SelectControl
          control={control}
          name="isDisactivated"
          label="Compte bloqué"
          error={errors.isDisactivated}
          items={[
            { value: 'false', label: 'non' },
            { value: 'true', label: 'oui' },
          ]}
          defaultValue="false"
          required
        />
      </Row>

      <Button
        type="submit"
        disabled={isNoChange}
        style={{
          display: 'flex',
          alignSelf: 'center',
          backgroundColor: !isNoChange ? Theme.colors.blueArtizen : Theme.colors.blueGray,
          color: 'white',
          width: 200,
        }}
      >
        Valider
      </Button>
    </form>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  ${Theme.font.size.xlarge};
  font-weight: 700;
  border-bottom: 1px solid;
`
