import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useMemo, useReducer, useState } from 'react'
import styled from 'styled-components'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Order } from 'components/table/infinite/InfiniteTable.interfaces'
import { TPayment } from '@Arti-zen/package-backoffice'
import { useNavigate } from 'react-router-dom'
import { factureListActions } from '../../store/store.facturation'
import { Format } from 'utils/format'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { useDebounce } from 'hooks/useDebounce'
import { EPaymentProgressStatus, TPaymentExtended } from '../../store/store.facturation.interfaces'
import { DatePicker } from '@mui/x-date-pickers'
import { LeftBar, WrapperFilter, WrapperCheckBoxes, Title } from '../FactureList/FactureList.styled'
import { DateTime } from 'luxon'
import { definitionFields } from './PaymentOrphanList.definitionFields'
import { filterPayment, statusListReducer } from './PaymentOrphanList.helper'

export default function PaymentOrphanList(): ReactElement {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { paymentOrphanList, isRefreshing } = useAppSelector((state) => state.facturation)
  const [search, setSearch] = useState<string>()
  const textSearched = useDebounce(search, 500)
  const [dateStart, setDateStart] = useState<DateTime | null>(null)
  const [dateEnd, setDateEnd] = useState<DateTime | null>(null)

  const fields = definitionFields(navigate, dispatch)

  const [displayedFields, setDisplayedFields] = useState<(keyof TPaymentExtended)[]>(fields.map((def) => def.indexName))
  const [activeSort, setActiveSort] = useState<{ key: keyof TPayment; order: Order }[]>([
    { key: 'createdAt', order: Order.DESC },
  ])

  const onSort = (key: keyof TPayment, order: Order) => {
    setActiveSort([{ key, order }])
  }
  useEffect(() => {
    dispatch(factureListActions.getPaymentOrphanList())
  }, [])

  const [paymentStatusList, setPaymentStatusList] = useReducer(statusListReducer, [
    EPaymentProgressStatus.inProgress,
    EPaymentProgressStatus.paid,
    EPaymentProgressStatus.unpaid,
  ])

  const displayedPaymentOrphanList = useMemo(() => {
    return filterPayment({ paymentOrphanList, textSearched, dateStart, dateEnd, paymentStatusList })
  }, [paymentOrphanList, textSearched, dateStart, dateEnd, paymentStatusList.length])

  return (
    <Wrapper>
      <LeftBar>
        <DatePicker
          label="Date de début"
          value={dateStart}
          onChange={(e) => setDateStart(e)}
          timezone="Europe/Paris"
          slotProps={{
            field: { clearable: true, onClear: () => setDateStart(null) },
          }}
        />

        <DatePicker
          label="Date de fin"
          value={dateEnd}
          onChange={(e) => setDateEnd(e)}
          timezone="Europe/Paris"
          slotProps={{
            field: { clearable: true, onClear: () => setDateEnd(null) },
          }}
        />

        <WrapperFilter>
          <Title>Status</Title>
          <WrapperCheckBoxes>
            {[EPaymentProgressStatus.inProgress, EPaymentProgressStatus.paid, EPaymentProgressStatus.unpaid].map(
              (status) => {
                return (
                  <FormControlLabel
                    key={status}
                    control={
                      <Checkbox
                        onChange={() => setPaymentStatusList(status)}
                        checked={paymentStatusList.includes(status)}
                        size="small"
                      />
                    }
                    label={`${status} (${displayedPaymentOrphanList.filter((payment) => payment.paymentProgress === status).length})`}
                    labelPlacement="end"
                    componentsProps={{ typography: { style: { fontSize: 14 } } }}
                  />
                )
              }
            )}
          </WrapperCheckBoxes>
        </WrapperFilter>
      </LeftBar>

      <WrapperContent>
        <TextField
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Rechercher un artisan (artisanId, email, gocardless id)"
          style={{ display: 'flex', width: '100%', marginBottom: 20 }}
        />

        <div
          style={{
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>
            {displayedPaymentOrphanList.length} paiements pour{' '}
            {Format.currency(
              displayedPaymentOrphanList.reduce((acc, payment) => acc + +payment.amountInCts, 0) / 100,
              2
            )}{' '}
            TTC
          </span>
          <Button onClick={() => dispatch(factureListActions.getList())} variant="contained" disabled={isRefreshing}>
            {isRefreshing ? '...' : 'Refresh'}
          </Button>
        </div>

        <TableInfinite<TPaymentExtended>
          displayedFields={displayedFields}
          setDisplayedFields={setDisplayedFields}
          definitionFields={fields}
          onSort={onSort}
          activeSorts={activeSort}
          data={displayedPaymentOrphanList}
          nextPage={() => {}}
          isQueriedAllCompleted={true}
          firstColumn={{
            format: () => <></>,
          }}
        />
      </WrapperContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`
