import { IInvoicesGet } from '@Arti-zen/package-backoffice'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { IInvoiceListState, InvoiceListStoreErrorType } from './store.invoiceList.interfaces'

const fetch = {
  action: createAsyncThunk('invoiceList/get', async (filters: IInvoicesGet | 'refresh', thunkApi) => {
    const state = thunkApi.getState() as RootState
    const newFilters = filters === 'refresh' ? { ...state.invoiceList.filters, page: 1 } : filters
    return ApiArtizenBO.invoices.get(newFilters)
  }),
  reducer: (builder: ActionReducerMapBuilder<IInvoiceListState>) => {
    builder
      .addCase(fetch.action.pending, (state, action) => {
        state.filters = action.meta.arg === 'refresh' ? { ...state.filters, page: 1 } : action.meta.arg
        state.errorType = null
        state.isLoading = true
      })
      .addCase(fetch.action.fulfilled, (state, action) => {
        state.isQueriedAllCompleted = action.payload.length < state.filters.limit
        state.invoices = action.payload
        state.isLoading = false
      })
      .addCase(fetch.action.rejected, (state, _action) => {
        state.invoices = []
        state.isLoading = false
        state.errorType = InvoiceListStoreErrorType.network
      })
  },
}

const fetchNextPage = {
  action: createAsyncThunk('invoiceList/getNextPage', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { filters } = state.invoiceList
    return ApiArtizenBO.invoices.get({ ...filters, page: filters.page })
  }),
  reducer: (builder: ActionReducerMapBuilder<IInvoiceListState>) => {
    builder
      .addCase(fetchNextPage.action.pending, (state, _action) => {
        state.errorType = null
        state.filters.page += 1
        state.isLoading = true
      })
      .addCase(fetchNextPage.action.fulfilled, (state, action) => {
        state.isQueriedAllCompleted = action.payload.length < state.filters.limit
        state.invoices = [...state.invoices, ...action.payload]
        state.isLoading = false
      })
      .addCase(fetchNextPage.action.rejected, (state, _action) => {
        state.isLoading = false
        state.errorType = InvoiceListStoreErrorType.network
      })
  },
}

export const asyncActions = {
  fetch: fetch.action,
  fetchNextPage: fetchNextPage.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IInvoiceListState>) => {
  fetch.reducer(builder)
  fetchNextPage.reducer(builder)
}
