import { useAppDispatch } from 'hooks/useStore'
import { ReactElement } from 'react'
import styled from 'styled-components'
import { authActions } from '../store/store.auth'

export default function Logout(): ReactElement {
  const dispatch = useAppDispatch()
  document.title = 'Logout'

  const onClick = () => {
    dispatch(authActions.logout())
  }

  return (
    <Wrapper>
      <Button onClick={onClick}>Se déconnecter</Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Button = styled.button``
