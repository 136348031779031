import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.invoice.thunks'
import { IInvoiceState } from './store.invoice.interfaces'

const initialState: IInvoiceState = {
  invoice: null,
}

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    init() {
      return initialState
    },
  },
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const invoiceActions = { ...invoiceSlice.actions, ...thunks.asyncActions }
export default invoiceSlice.reducer
