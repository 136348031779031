import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import TableHeaders from './components/TableHeader'
import { ITableInfinite } from './InfiniteTable.interfaces'
import { InfiniteTableProvider, useInfiniteTableContext } from './InfiniteTable.context'
import TableContent from './components/TableContent'
import { Theme } from 'theme/theme'
import CsvDownloadButton from 'react-json-to-csv'
import { Button } from '@mui/material'

export default function TableInfinite<T>(input: Readonly<ITableInfinite<T>>): ReactElement {
  const [isLoadingAll, setIsLoadingAll] = useState(false)

  const getAll = () => {
    setIsLoadingAll(true)
    input.getAll()
  }

  return (
    <InfiniteTableProvider input={input}>
      <Wrapper>
        {input.exportFileName && input.isQueriedAllCompleted ? (
          <CsvDownloadButton
            filename={input.exportFileName}
            data={input.data}
            style={{ display: 'flex', marginBottom: 15, padding: 5, width: 400, justifyContent: 'center' }}
          >
            Télécharger - {input.exportFileName}
          </CsvDownloadButton>
        ) : null}
        {input.getAll && !input.isQueriedAllCompleted ? (
          <Button
            onClick={getAll}
            style={{ display: 'flex', marginBottom: 15, padding: 5, width: 400, justifyContent: 'center' }}
            variant="contained"
          >
            {isLoadingAll ? 'Chargement en cours...' : 'Tout charger'}
          </Button>
        ) : null}
        <TableHeaders<T> />
        <ErrorMessage<T> />
        <TableContent<T> />
      </Wrapper>
    </InfiniteTableProvider>
  )
}

const ErrorMessage = <T,>(): ReactElement => {
  const { displayError, data } = useInfiniteTableContext<T>()

  if (displayError) return <EmptyText>{displayError}</EmptyText>
  if (data.length === 0) return <EmptyText>Aucun résultat trouvé pour cette recherche</EmptyText>

  return null
}

const Wrapper = styled.div<{ height?: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  ${(props) => (props.height ? 'max-height: ' + props.height + 'px' : '')};
  height: 100%;
  width: 100%;
`

export const EmptyText = styled.div`
  font-family: ${Theme.font.family};
  ${Theme.font.size.normal};
  font-style: italic;
  text-align: center;
  margin-top: 50px;
`
