import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { IDashboardState } from './store.dashboard.interfaces'
import { RootState } from 'store/store'

const getInterventions = {
  action: createAsyncThunk('dashboard/getInterventions', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { dateStart, dateEnd } = state.dashboard
    return ApiArtizenBO.interventions.stats({
      excludedUserIdList: [],
      dateStart: dateStart?.toFormat('yyyy/MM/dd'),
      dateEnd: dateEnd?.toFormat('yyyy/MM/dd'),
    })
  }),
  reducer: (builder: ActionReducerMapBuilder<IDashboardState>) => {
    builder
      .addCase(getInterventions.action.pending, (state, action) => {
        state.interventions = []
        state.interventionStats = null
        state.isInterStatReady = false
      })
      .addCase(getInterventions.action.fulfilled, (state, action) => {
        state.interventions = action.payload.interventionList
        state.interventionStats = action.payload.stats
      })
      .addCase(getInterventions.action.rejected, (state, _action) => {
        state.interventions = []
        state.interventionStats = null
      })
  },
}

const getQuotations = {
  action: createAsyncThunk('dashboard/getQuotations', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { dateStart, dateEnd } = state.dashboard
    return ApiArtizenBO.quotations.stats({
      excludedUserIdList: [],
      dateStart: dateStart?.toFormat('yyyy/MM/dd'),
      dateEnd: dateEnd?.toFormat('yyyy/MM/dd'),
    })
  }),
  reducer: (builder: ActionReducerMapBuilder<IDashboardState>) => {
    builder
      .addCase(getQuotations.action.pending, (state, action) => {
        state.quotations = []
        state.quotationStats = null
        state.isInvStatReady = false
      })
      .addCase(getQuotations.action.fulfilled, (state, action) => {
        state.quotations = action.payload.quotationList
        state.quotationStats = action.payload.stats
      })
      .addCase(getQuotations.action.rejected, (state, _action) => {
        state.quotations = []
        state.quotationStats = null
      })
  },
}

const getInvoices = {
  action: createAsyncThunk('dashboard/getInvoices', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { dateStart, dateEnd } = state.dashboard
    return ApiArtizenBO.invoices.stats({
      excludedUserIdList: [],
      dateStart: dateStart?.toFormat('yyyy/MM/dd'),
      dateEnd: dateEnd?.toFormat('yyyy/MM/dd'),
    })
  }),
  reducer: (builder: ActionReducerMapBuilder<IDashboardState>) => {
    builder
      .addCase(getInvoices.action.pending, (state, action) => {
        state.invoices = []
        state.invoiceStats = null
        state.isQuotStatReady = false
      })
      .addCase(getInvoices.action.fulfilled, (state, action) => {
        state.invoices = action.payload.invoiceList
        state.invoiceStats = action.payload.stats
      })
      .addCase(getInvoices.action.rejected, (state, _action) => {
        state.invoices = []
        state.invoiceStats = null
      })
  },
}

const getPartnerStats = {
  action: createAsyncThunk('dashboard/getPartnerStats', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { dateStart, dateEnd } = state.dashboard
    return ApiArtizenBO.partnerProducts.stats({
      dateStart: dateStart?.toFormat('yyyy/MM/dd'),
      dateEnd: dateEnd?.toFormat('yyyy/MM/dd'),
    })
  }),
  reducer: (builder: ActionReducerMapBuilder<IDashboardState>) => {
    builder
      .addCase(getPartnerStats.action.pending, (state, action) => {
        state.partnerStatList = []
        state.userLinkedList = []
        state.partnerStats = null
      })
      .addCase(getPartnerStats.action.fulfilled, (state, action) => {
        state.partnerStatList = action.payload.partnerStatList
        state.userLinkedList = action.payload.userLinkedList
        state.partnerStats = action.payload.stats
      })
      .addCase(getPartnerStats.action.rejected, (state, _action) => {})
  },
}

const getArtisans = {
  action: createAsyncThunk('dashboard/getArtisans', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { dateStart, dateEnd } = state.dashboard
    return ApiArtizenBO.artizenUsers.stats({
      excludedUserIdList: [],
      dateStart: dateStart?.toFormat('yyyy/MM/dd'),
      dateEnd: dateEnd?.toFormat('yyyy/MM/dd'),
    })
  }),
  reducer: (builder: ActionReducerMapBuilder<IDashboardState>) => {
    builder
      .addCase(getArtisans.action.pending, (state, action) => {
        state.artisans = []
        state.artisanStats = null
      })
      .addCase(getArtisans.action.fulfilled, (state, action) => {
        state.artisans = action.payload.artisanList
        state.artisanStats = action.payload.stats
      })
      .addCase(getArtisans.action.rejected, (state, _action) => {
        state.artisans = []
        state.artisanStats = null
      })
  },
}

export const asyncActions = {
  getInterventions: getInterventions.action,
  getQuotations: getQuotations.action,
  getInvoices: getInvoices.action,
  getArtisans: getArtisans.action,
  getPartnerStats: getPartnerStats.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IDashboardState>) => {
  getInterventions.reducer(builder)
  getQuotations.reducer(builder)
  getInvoices.reducer(builder)
  getArtisans.reducer(builder)
  getPartnerStats.reducer(builder)
}
