import { Client, IInvoicesGet, Intervention, InvoiceComptaStatus } from '@Arti-zen/package-backoffice'

export enum InvoiceStatusFilter {
  'all' = 'all',
  'draft' = 'draft',
  'paid' = 'paid',
  'unpaid' = 'unpaid',
}

export const statusByMenu: { [key in InvoiceStatusFilter]: InvoiceComptaStatus[] } = {
  all: [InvoiceComptaStatus.Paid, InvoiceComptaStatus.Unpaid, InvoiceComptaStatus.Draft],
  draft: [InvoiceComptaStatus.Draft],
  paid: [InvoiceComptaStatus.Paid],
  unpaid: [InvoiceComptaStatus.Unpaid],
}

export interface IInvoiceList {
  search?: string
  filterBase?: {
    clientId?: Client['id']
    status?: IInvoicesGet['status']
    interventionIds?: Intervention['id'][]
    startDate?: Date
    endDate?: Date
    types?: IInvoicesGet['types']
  }
}
