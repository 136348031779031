import { EPaymentProgressStatus, TPaymentExtended } from '../../store/store.facturation.interfaces'
import { DateTime } from 'luxon'

export const filterPayment = ({
  paymentOrphanList,
  textSearched,
  dateStart,
  dateEnd,
  paymentStatusList,
}: {
  paymentOrphanList: TPaymentExtended[]
  textSearched: string
  dateStart: DateTime | null
  dateEnd: DateTime | null
  paymentStatusList: EPaymentProgressStatus[]
}) => {
  return paymentOrphanList.filter((payment) => {
    const searchTest = textSearched
      ? [
          String(payment?.artisan?.id).includes(textSearched),
          payment?.artisan?.email.includes(textSearched),
          payment?.GCLid.includes(textSearched),
        ].some((test) => test === true)
      : true

    return [
      searchTest,
      dateStart ? dateStart.startOf('day').diff(payment.chargeDateTime, 'milliseconds').milliseconds < 0 : true,
      dateEnd ? dateEnd.endOf('day').diff(payment.chargeDateTime, 'milliseconds').milliseconds > 0 : true,
      paymentStatusList.includes(payment.paymentProgress),
    ].every((test) => test === true)
  })
}

export const statusListReducer = (state: EPaymentProgressStatus[], action: EPaymentProgressStatus) => {
  if (state.includes(action)) {
    return state.filter((state) => state !== action)
  } else {
    return [...state, action]
  }
}
