import { IInterventionsGet, InterventionClass } from '@Arti-zen/package-backoffice'

export interface IInterventionListState {
  isLoading: boolean
  errorType?: InterventionListStoreErrorType
  isQueriedAllCompleted: boolean
  interventions: InterventionClass['meilisearchDoc'][]
  filters?: IInterventionsGet
}

export enum InterventionListStoreErrorType {
  network = 'network',
  dbLinkedDocument = 'dbLinkedDocument',
  sendByEmail = 'sendByEmail',
}
