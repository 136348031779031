import { ReactElement } from 'react'
import { Route } from 'react-router-dom'
import { pages } from 'navigation/pages'
import DashboardLayout from './DashboardLayout'
import DashboardFigures from '../components/DashboardFigures/DashboardFigures'
import DashboardMrr from '../components/DashboardMrr'
import DashboardMrrOneShot from '../components/DashboardMrrOneShot'
import { RouteProtected } from 'navigation/navigation.helper'
import { Roles } from '@Arti-zen/package-backoffice'
import DashboardPartnerFigures from '../components/DashboardPartnerFigures/DashboardPartnerFigures'

export default function DashboardRouter(): ReactElement {
  return (
    <Route
      path={pages.connected.dashboard.root.name}
      element={RouteProtected(<DashboardLayout />, [Roles.SUPER_ADMIN, Roles.ADMIN])}
    >
      <Route path={pages.connected.dashboard.figures.name} element={<DashboardFigures />} />
      <Route path={pages.connected.dashboard.mrr.name} element={<DashboardMrr />} />
      <Route path={pages.connected.dashboard.mrrOneShot.name} element={<DashboardMrrOneShot />} />
      <Route path={pages.connected.dashboard.figuresPartner.name} element={<DashboardPartnerFigures />} />
    </Route>
  )
}
