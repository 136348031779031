import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useAppDispatch } from 'hooks/useStore'
import { authActions } from 'modules/auth/store/store.auth'
import RouterMain from 'navigation/RouterMain'
import { ReactElement, useEffect, useState } from 'react'

export default function App(): ReactElement {
  const [initOk, setInitOk] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(authActions.me())
      .unwrap()
      .then(() => setInitOk(true))
      .catch(() => setInitOk(true))
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      {initOk ? <RouterMain /> : <>Initialiation du BO ...</>}
    </LocalizationProvider>
  )
}
