import { ReactElement, createContext, useContext } from 'react'
import { ITableInfinite } from './InfiniteTable.interfaces'

const InfiniteTableContext = createContext<ITableInfinite<any>>(undefined)

export const InfiniteTableProvider = <T,>({
  children,
  input,
}: {
  children: ReactElement
  input: ITableInfinite<T>
}) => {
  return <InfiniteTableContext.Provider value={input}>{children}</InfiniteTableContext.Provider>
}

export const useInfiniteTableContext = <T,>(): ITableInfinite<T> => useContext(InfiniteTableContext)
