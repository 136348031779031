import { useAppSelector } from 'hooks/useStore'
import { CSSProperties, ReactElement, useMemo, useState } from 'react'
import styled from 'styled-components'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { TUserLinked } from '@Arti-zen/package-backoffice'
import SearchInputSync from 'components/forms/SearchInputSync'
import { useDebounce } from 'hooks/useDebounce'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import { pages } from 'navigation/pages'
import DownloadButton from 'components/DownloadButton'

const definitionFields = (navigate: NavigateFunction): ITableInfinite<TUserLinked>['definitionFields'] => {
  return [
    {
      indexName: 'id',
      label: 'Id',
      isSortable: true,
      sortField: 'id',
      format: ({ id }) => (
        <Link to={pages.connected.artisan.profile.navigate(id)} target="_blank">
          {id}
        </Link>
      ),
    },
    {
      indexName: 'email',
      label: 'email',
      onClick: ({ id }) => {
        navigate(pages.connected.artisan.profile.navigate(id))
      },
    },
    {
      indexName: 'source',
      label: 'TAG BO',
    },
    {
      indexName: 'partnerName',
      label: 'compte partner',
    },
    {
      indexName: 'identification',
      label: 'identification',
    },
  ]
}

export default function ArtisanLinkedList({ show }: { show: boolean }): ReactElement {
  const navigate = useNavigate()
  const { userLinkedList } = useAppSelector((state) => state.dashboard)

  const fields = definitionFields(navigate)

  const [displayedFields, setDisplayedFields] = useState<(keyof TUserLinked)[]>(fields.map((def) => def.indexName))

  const [search, setSearch] = useState<string>(null)
  const activeSearch = useDebounce(search, 200)

  const displayedList = useMemo(() => {
    const listNoTereva = userLinkedList.filter(({ partnerName }) => partnerName !== 'tereva')

    if (!activeSearch) return listNoTereva

    return listNoTereva.filter(({ email, id, identification }) =>
      [email.includes(activeSearch), String(id).includes(activeSearch), identification?.includes(activeSearch)].some(
        (test) => test === true
      )
    )
  }, [activeSearch, userLinkedList])

  return (
    <Wrapper show={show}>
      <div
        style={{ display: 'flex', flexDirection: 'row', width: '75%', gap: 20, alignItems: 'center', marginBottom: 20 }}
      >
        <SearchInputSync
          onChange={setSearch}
          placeholder="Taper votre recherche"
          wrapperStyle={{ marginBottom: 0 }}
          displayCloseCross
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '75%',
          gap: 20,
          justifyContent: 'center',
          alignItems: 'flex-start',
          marginBottom: 20,
        }}
      >
        <DisplayStats displayedList={displayedList} />
        <DownloadButton data={displayedList} fileName="linked-artisan" />
      </div>

      <TableInfinite<TUserLinked>
        displayedFields={displayedFields}
        setDisplayedFields={setDisplayedFields}
        definitionFields={fields}
        data={displayedList}
        nextPage={() => {}}
        isQueriedAllCompleted={true}
        firstColumn={{
          format: () => <></>,
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const DisplayStats = ({ displayedList }: { displayedList: TUserLinked[] }) => {
  return (
    <div style={{ marginBottom: 15 }}>
      <Stat label="Total Richardson" value={displayedList.length} />
      <Stat
        label="TAG OK / linked OK"
        value={
          displayedList.filter(({ source, partnerName }) => source === 'RICHARDSON' && partnerName === 'RICHARDSON')
            .length
        }
        type="success"
      />
      <Stat
        label="TAG KO / linked OK"
        value={
          displayedList.filter(({ source, partnerName }) => source !== 'RICHARDSON' && partnerName === 'RICHARDSON')
            .length
        }
        type="warning"
      />
      <Stat
        label="TAG OK / linked KO"
        value={
          displayedList.filter(({ source, partnerName }) => source === 'RICHARDSON' && partnerName !== 'RICHARDSON')
            .length
        }
        type="error"
      />
    </div>
  )
}

const Stat = ({
  label,
  value,
  type = 'total',
}: {
  label: string
  value: string | number
  type?: 'total' | 'success' | 'warning' | 'error'
}) => {
  const typeStyle: { [key: string]: CSSProperties } = {
    total: { fontWeight: 900 },
    success: { color: 'green', fontStyle: 'italic' },
    warning: { color: 'orange', fontStyle: 'italic' },
    error: { color: 'red', fontStyle: 'italic' },
  }

  return (
    <div
      style={{
        ...typeStyle[type],
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: 20,
        width: 300,
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'flex', flex: 3 }}>{label}: </div>
      <div style={{ display: 'flex', flex: 1 }}>{value}</div>
    </div>
  )
}
