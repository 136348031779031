import { Facture, TPayment } from '@Arti-zen/package-backoffice'

export const factureListPages = {
  root: '/facture',
  factures: {
    name: '/facture/list',
    navigate: () => `/facture/list`,
  },
  factureMissingList: {
    name: '/facture/missinglist',
    navigate: () => `/facture/missinglist`,
  },
  paymentOrphanList: {
    name: '/facture/paymentOrphanList',
    navigate: () => `/facture/paymentOrphanList`,
  },
  payment: {
    name: '/facture/payment/:id',
    navigate: (id?: TPayment['id']) => `/facture/payment/${id}`,
  },
  unpaidByUserList: {
    name: '/facture/unpaidByUserList',
    navigate: () => `/facture/unpaidByUserList`,
  },
  facture: {
    name: '/facture/:id',
    navigate: (id?: Facture['id']) => `/facture/${id}`,
  },
}
