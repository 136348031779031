import { UserArtizen } from '@Arti-zen/package-backoffice'
import { useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { Theme } from 'theme/theme'
import { NavLink } from 'react-router-dom'
import { pages } from 'navigation/pages'

export default function ArtisanSubAccounts(): ReactElement {
  const { artisan, children, isLoading } = useAppSelector((state) => state.artisan)

  const isParent = !artisan.parentId

  return !isLoading && artisan ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      <Title>{isParent ? 'Comptes Salariés' : 'Compte patron'}</Title>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', fontWeight: 600 }}>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>Id</div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>Email</div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>Abonné</div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>Bloqué</div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>Date abonnement</div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>Date résiliation</div>
      </div>
      {isParent ? (
        children.map((child) => <Child key={`Child-${child.id}`} child={child} />)
      ) : (
        <Child child={artisan.parent} />
      )}
    </div>
  ) : null
}

const Child = ({ child }: { child: UserArtizen }): ReactElement => {
  const { id, email, isSubscribed, subscriptionDate, resiliationDatetime, isDisactivated } = child

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
      <NavLink
        style={{ display: 'flex', flex: 1, justifyContent: 'center' }}
        to={pages.connected.artisan.profile.navigate(id)}
      >
        {id}
      </NavLink>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>{email}</div>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>{isSubscribed ? 'oui' : 'non'}</div>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>{isDisactivated ? 'oui' : 'non'}</div>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        {subscriptionDate && DateTime.fromISO(subscriptionDate).toFormat('dd/MM/yyyy')}
      </div>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        {resiliationDatetime && DateTime.fromISO(resiliationDatetime).toFormat('dd/MM/yyyy')}
      </div>
    </div>
  )
}

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  ${Theme.font.size.xlarge};
  font-weight: 700;
  border-bottom: 1px solid;
`
