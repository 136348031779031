import { ReactElement, useEffect } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import { pages } from 'navigation/pages'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { factureListActions } from '../store/store.facturation'
import { artisanListActions } from 'modules/artisanList/store/store.artisanList'

export default function FactureListLayout(): ReactElement {
  document.title = 'FACTURATION'
  const dispatch = useAppDispatch()
  const { dateStart, dateEnd } = useAppSelector((state) => state.facturation)
  const { artisanListBase } = useAppSelector((state) => state.artisanList)

  useEffect(() => {
    dispatch(artisanListActions.getAll())
  }, [])

  useEffect(() => {
    if (artisanListBase?.length > 0) {
      dispatch(factureListActions.getList())
      dispatch(factureListActions.getFactureMissingList())
    }
  }, [dateStart, dateEnd, artisanListBase])

  return (
    <Wrapper>
      <Nav>
        <Title>FACTURATION</Title>
        <NavLink to={pages.connected.factureList.factures.navigate()}>Factures</NavLink>
        <NavLink to={pages.connected.factureList.factureMissingList.navigate()}>Factures manquantes</NavLink>
        <NavLink to={pages.connected.factureList.paymentOrphanList.navigate()}>Paiments sans facture</NavLink>
        <NavLink to={pages.connected.factureList.unpaidByUserList.navigate()}>Impayés par user</NavLink>
      </Nav>
      <WrapperContent>
        <Outlet />
      </WrapperContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  border-right: solid 1px ${Theme.colors.greyDark};
  padding-right: 15px;
  font-size: 15px;
  gap: 20px;
  a {
    color: ${Theme.colors.blueArtizen};
    font-weight: 700;
    text-decoration: none;
  }

  a.active {
    color: ${Theme.colors.greenArtizen};
  }
`

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid ${Theme.colors.blueArtizen};
  color: ${Theme.colors.blueArtizen};
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`
