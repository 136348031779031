import { Button } from '@mui/material'
import DownloadButton from 'components/DownloadButton'
import { useAppSelector } from 'hooks/useStore'
import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { ApiArtizenBO } from 'utils/artizenConnector'

export default function ArtisanExtracts(): ReactElement {
  const { artisan } = useAppSelector((state) => state.artisan)
  const [clients, setClients] = useState([])
  const [products, setProducts] = useState([])

  const getClients = async () => {
    const clientList = await ApiArtizenBO.extracts.getClientsByCompany(artisan.id)
    setClients(clientList)
  }

  const getProducts = async () => {
    const productList = await ApiArtizenBO.extracts.getProductsByCompany(artisan.id)
    setProducts(productList)
  }

  return (
    <Wrapper>
      {clients.length > 0 ? (
        <DownloadButton
          data={clients}
          fileName={`${artisan.id}_clients_${new Date().toISOString()}`}
          label={'Récupérer csv clients'}
          size="medium"
        />
      ) : (
        <Button variant="contained" onClick={getClients}>
          Clients
        </Button>
      )}

      {products.length > 0 ? (
        <DownloadButton
          data={products}
          fileName={`${artisan.id}_products_${new Date().toISOString()}`}
          label={'Récupérer csv produits'}
          size="medium"
        />
      ) : (
        <Button variant="contained" onClick={getProducts}>
          Produits
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 50px;
`
