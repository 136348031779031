import { InvoiceClass, IInvoicesGet } from '@Arti-zen/package-backoffice'

export interface IInvoiceListState {
  isLoading: boolean
  errorType?: InvoiceListStoreErrorType
  isQueriedAllCompleted: boolean
  invoices: InvoiceClass['meilisearchDoc'][]
  filters?: IInvoicesGet
}

export enum InvoiceListStoreErrorType {
  network = 'network',
  dbLinkedDocument = 'dbLinkedDocument',
  sendByEmail = 'sendByEmail',
}
