import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import { invoiceActions } from '../store/store.invoice'

export default function Invoice(): ReactElement {
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()
  const { invoice } = useAppSelector((state) => state.invoice)

  useEffect(() => {
    if (id) {
      dispatch(invoiceActions.get(+id))
    }
  }, [id])

  return invoice ? (
    <Wrapper>
      <iframe
        src={`${invoice.pdfUrl}#zoom=85%?time=${new Date().getTime()}`}
        title="PDF"
        style={{
          display: 'flex',
          flex: 1,
          padding: 30,
          background: 'transparent',
          border: 'none',
          width: '100%',
        }}
      />

      <button
        style={{
          backgroundColor: Theme.colors.blueArtizen,
          color: 'white',
          fontSize: 25,
          border: 'none',
          borderRadius: 10,
          width: 500,
          margin: 15,
          padding: 15,
        }}
        onClick={() => dispatch(invoiceActions.generatePdf(+id))}
      >
        Regénérer le PDF
      </button>
    </Wrapper>
  ) : null
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
