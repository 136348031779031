import { useAppDispatch } from 'hooks/useStore'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { NavLink, NavigateFunction } from 'react-router-dom'
import { Format } from 'utils/format'
import { pages } from 'navigation/pages'
import { DisplayPaymentProgress } from 'components/FactureList/FactureList.definitionFields'
import { TPaymentExtended } from '../../store/store.facturation.interfaces'

export const definitionFields = (
  navigate: NavigateFunction,
  dispatch: ReturnType<typeof useAppDispatch>
): ITableInfinite<TPaymentExtended>['definitionFields'] => {
  return [
    {
      indexName: 'GCLid',
      label: 'GoCardLess Id',
      format: ({ id, GCLid }) => <NavLink to={pages.connected.factureList.payment.navigate(id)}>{GCLid}</NavLink>,
    },
    {
      indexName: 'artisan',
      label: 'Artisan',
      format: ({ artisan }) =>
        artisan ? (
          <NavLink to={pages.connected.artisan.profile.navigate(artisan.id)} target="_blank">
            {artisan.id} - {artisan.email}
          </NavLink>
        ) : (
          <></>
        ),
    },
    {
      indexName: 'amountInCts',
      label: 'Montant TTC',
      format: ({ amountInCts }) => Format.currency(amountInCts / 100, 2),
    },
    {
      indexName: 'chargeDate',
      label: 'Date prélèvement',
      format: ({ chargeDate }) => Format.dateFr(chargeDate),
    },
    {
      indexName: 'paymentProgress',
      label: 'Statut',
      format: ({ paymentProgress }) => <DisplayPaymentProgress paymentProgress={paymentProgress} />,
    },
  ]
}
