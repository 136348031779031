import { Autocomplete, FormControlLabel, MenuItem, Select, TextField } from '@mui/material'
import { ReactElement } from 'react'

type TItemAutocomplete = {
  value: any
  label: string
}

export type TAutocompleteBase = {
  label?: string
  items: TItemAutocomplete[]
  onChange: (value: TItemAutocomplete['value']) => void
}

export default function AutocompleteBase({ items, label, onChange }: Readonly<TAutocompleteBase>): ReactElement {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={items}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(_event, item) => onChange(item?.value)}
      isOptionEqualToValue={(option, value) => option.value === value}
      getOptionLabel={(option) => option.label}
    />
  )
}
