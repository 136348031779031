import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect } from 'react'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import { artisanActions } from '../store/store.artisan'
import { pages } from 'navigation/pages'

export default function ArtisanLayout(): ReactElement {
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()
  const { artisan } = useAppSelector((state) => state.artisan)
  document.title = artisan?.lastName || artisan?.firstName ? artisan?.lastName + ' ' + artisan?.firstName : id

  useEffect(() => {
    if (id) {
      dispatch(artisanActions.get(+id))
      dispatch(artisanActions.fetchFactures(+id))
    }
  }, [id])

  return (
    <Wrapper>
      <Nav>
        <Title>
          {artisan?.lastName} {artisan?.firstName}
        </Title>
        <NavLink to={pages.connected.artisan.profile.navigate(+id)}>Profile</NavLink>
        <NavLink to={pages.connected.artisan.subscription.navigate(+id)}>Abonnement</NavLink>
        <NavLink to={pages.connected.artisan.interventionList.navigate(+id)}>Interventions</NavLink>
        <NavLink to={pages.connected.artisan.quotationList.navigate(+id)}>Devis</NavLink>
        <NavLink to={pages.connected.artisan.invoiceList.navigate(+id)}>Factures</NavLink>
        <NavLink to={pages.connected.artisan.clientList.navigate(+id)}>Clients</NavLink>
        <NavLink to={pages.connected.artisan.dashboard.navigate(+id)}>Dashboard</NavLink>
        <NavLink to={pages.connected.artisan.facturation.navigate(+id)}>Facturation</NavLink>
        <NavLink to={pages.connected.artisan.extracts.navigate(+id)}>Extracts</NavLink>
      </Nav>
      <WrapperContent>{artisan ? <Outlet /> : null}</WrapperContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  border-right: solid 1px ${Theme.colors.greyDark};
  padding-right: 15px;
  font-size: 15px;
  gap: 20px;
  a {
    color: ${Theme.colors.blueArtizen};
    font-weight: 700;
    text-decoration: none;
  }

  a.active {
    color: ${Theme.colors.greenArtizen};
  }
`

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid ${Theme.colors.blueArtizen};
  color: ${Theme.colors.blueArtizen};
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`
