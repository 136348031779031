import { ReactElement } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import { pages } from 'navigation/pages'

export default function DashboardLayout(): ReactElement {
  document.title = 'DASHBOARD'
  return (
    <Wrapper>
      <Nav>
        <NavLink to={pages.connected.dashboard.figures.navigate()}>Stats</NavLink>
        <NavLink to={pages.connected.dashboard.figuresPartner.navigate()}>Partners</NavLink>
        <NavLink to={pages.connected.dashboard.mrr.navigate()}>CA HT</NavLink>
        <NavLink to={pages.connected.dashboard.mrrOneShot.navigate()}>MRR à date</NavLink>
      </Nav>
      <WrapperContent>
        <Outlet />
      </WrapperContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  border-right: solid 1px ${Theme.colors.greyDark};
  padding-right: 15px;
  font-size: 15px;
  gap: 20px;
  a {
    color: ${Theme.colors.blueArtizen};
    font-weight: 700;
    text-decoration: none;
  }

  a.active {
    color: ${Theme.colors.greenArtizen};
  }
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`
