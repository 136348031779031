import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Order } from 'components/table/infinite/InfiniteTable.interfaces'
import { Facture, FactureErrorState, FactureState, UserArtizen } from '@Arti-zen/package-backoffice'
import { useNavigate } from 'react-router-dom'
import { factureListActions } from '../../store/store.facturation'
import { Format } from 'utils/format'
import { DatePicker } from '@mui/x-date-pickers'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { EPaymentProgressStatus, FactureExtended } from '../../store/store.facturation.interfaces'
import CsvDownloadButton from 'react-json-to-csv'
import { definitionFields } from './UnpaidByUserList.definitionFields'
import { Wrapper, LeftBar, WrapperFilter, WrapperCheckBoxes, WrapperContent, Title } from './UnpaidByUserList.styled'
import { useDebounce } from 'hooks/useDebounce'
import { text } from 'node:stream/consumers'

export type TUnpaidByUser = {
  id: UserArtizen['id']
  user: UserArtizen
  nbUnpaid: number
  totalTTC: number
}

const extractUnpaidFactureList = (factureList: FactureExtended[]): { [key: number]: FactureExtended[] } => {
  const list: { [key: UserArtizen['id']]: FactureExtended[] } = {}

  factureList
    .filter((fact) => fact.paymentProgress === EPaymentProgressStatus.unpaid)
    .forEach((fact) => {
      const userId = fact.clientId
      if (!list[userId]) list[userId] = []

      list[userId] = [...list[userId], fact]
    })

  return list
}

export default function UnpaidByUserList(): ReactElement {
  const dispatch = useAppDispatch()
  const { factures, dateStart, dateEnd } = useAppSelector((state) => state.facturation)
  const { artisanListBase } = useAppSelector((state) => state.artisanList)
  const [search, setSearch] = useState<string>()
  const textSearched = useDebounce(search, 500)

  const unpaidList: TUnpaidByUser[] = useMemo(() => {
    const unpaidByUser = extractUnpaidFactureList(factures)

    const unpaidList: TUnpaidByUser[] = Object.keys(unpaidByUser)
      .map((userId) => {
        const facts: FactureExtended[] = unpaidByUser[userId]

        return {
          id: +userId,
          user: artisanListBase.find((artisan) => +artisan.id === +userId),
          nbUnpaid: facts.length,
          totalTTC: facts.reduce((acc, fact) => acc + +fact.amountTTC, 0),
        }
      })
      .sort((a, b) => b.totalTTC - a.totalTTC)

    return unpaidList
  }, [factures, artisanListBase])

  const displayedList = useMemo(() => {
    if (!textSearched) return unpaidList

    return unpaidList.filter((unpaid) => {
      const tests = [String(unpaid?.user?.id).includes(textSearched), unpaid.user?.email.includes(textSearched)]

      return tests.some((test) => test === true)
    })
  }, [unpaidList, textSearched])

  const fields = definitionFields()

  const [displayedFields, setDisplayedFields] = useState<(keyof TUnpaidByUser)[]>(fields.map((def) => def.indexName))
  const [activeSort, setActiveSort] = useState<{ key: keyof TUnpaidByUser; order: Order }[]>([
    { key: 'id', order: Order.DESC },
  ])

  const onSort = (key: keyof TUnpaidByUser, order: Order) => {
    setActiveSort([{ key, order }])
  }

  if (!(artisanListBase?.length > 0 && factures?.length > 0)) return <div>"Chargement en cours..."</div>

  return (
    <Wrapper>
      <LeftBar>
        <DatePicker
          label="Date de début"
          value={dateStart}
          onChange={(e) => dispatch(factureListActions.setDateStart(e))}
          timezone="Europe/Paris"
        />

        <DatePicker label="Date de fin" value={dateEnd} onChange={(e) => dispatch(factureListActions.setDateEnd(e))} />
      </LeftBar>

      <WrapperContent>
        <TextField
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Rechercher un artisan (artisanId, email)"
          style={{ display: 'flex', width: '100%', marginBottom: 20 }}
        />
        <TableInfinite<TUnpaidByUser>
          displayedFields={displayedFields}
          setDisplayedFields={setDisplayedFields}
          definitionFields={fields}
          onSort={onSort}
          activeSorts={activeSort}
          data={displayedList}
          nextPage={() => {}}
          isQueriedAllCompleted={true}
          firstColumn={{
            format: () => <></>,
          }}
        />
      </WrapperContent>
    </Wrapper>
  )
}
