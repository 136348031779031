import { useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import styled from 'styled-components'
import FactureList from 'components/FactureList/FactureList'

export default function ArtisanFacturation(): ReactElement {
  const { artisan, isLoading, factureList } = useAppSelector((state) => state.artisan)

  return !isLoading && artisan ? (
    <Wrapper>
      <FactureList factureList={factureList} />
    </Wrapper>
  ) : null
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
