import { ReactElement } from 'react'
import CsvDownloadButton from 'react-json-to-csv'
import { Theme } from 'theme/theme'
import { GetApp } from '@mui/icons-material'
import React from 'react'

type TDownloadButton = {
  data: any[]
  label?: string
  fileName: string
  style?: React.CSSProperties
  Icon?: ReactElement
  size?: 'small' | 'medium'
}

export default function DownloadButton({
  data,
  fileName,
  style = {},
  Icon = <GetApp />,
  label = 'Télecharger',
  size = 'medium',
}: Readonly<TDownloadButton>): ReactElement {
  return (
    <CsvDownloadButton
      data={data}
      filename={fileName}
      style={{
        backgroundColor: Theme.colors.greyLighter,
        color: Theme.colors.blueArtizen,
        border: `${Theme.colors.blueArtizen} 1px solid`,
        padding: size === 'small' ? 0 : 5,
        cursor: 'pointer',
        ...style,
      }}
    >
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          transform: `scale(${size === 'small' ? 0.7 : 1})`,
        }}
      >
        {Icon} {label}
      </div>
    </CsvDownloadButton>
  )
}
