import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.clientList.thunks'
import { IClientListState } from './store.clientList.interfaces'

const initialState: IClientListState = {
  isLoading: false,
  isQueriedAllCompleted: false,
  clients: [],
}

const clientListSlice = createSlice({
  name: 'clientList',
  initialState,
  reducers: {},
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const clientListActions = { ...clientListSlice.actions, ...thunks.asyncActions }
export default clientListSlice.reducer
