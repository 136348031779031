import { ReactElement, useEffect, useRef } from 'react'

function useOutsideClickHandler(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default function OutsideClickHandler(props): ReactElement {
  const wrapperRef = useRef(null)
  useOutsideClickHandler(wrapperRef, props.onOutsideClick)

  return (
    <div ref={wrapperRef} style={props.style}>
      {props.children}
    </div>
  )
}
