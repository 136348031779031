import { UserArtizen, UserSource } from '@Arti-zen/package-backoffice'
import { Button } from '@mui/material'
import InputTextControl from 'components/forms/InputText.control'
import SelectControl from 'components/forms/Select.control'
import { ReactElement, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import { yupResolver } from '@hookform/resolvers/yup'
import { TArtisan, artisanSchema } from '../../store/store.artisan.interfaces'

interface IArtisanForm {
  artisan: UserArtizen
  onSubmit: SubmitHandler<Partial<UserArtizen>>
}

export default function ArtisanProfileForm({ artisan, onSubmit }: Readonly<IArtisanForm>): ReactElement {
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
  } = useForm<TArtisan>({
    //@ts-expect-error no comment
    defaultValues: { isSelfEmployed: false, ...artisan },
    resolver: yupResolver(artisanSchema),
    reValidateMode: 'onChange',
  })

  const isNoChange = useMemo(() => Object.keys(dirtyFields).length === 0, [dirtyFields])

  const onValidated = (data: UserArtizen) => {
    const changedFieldKey = Object.keys(dirtyFields) as (keyof UserArtizen)[]
    if (changedFieldKey.length > 0) {
      const changes = {}
      changedFieldKey.map((dirtyField) => (changes[dirtyField] = data[dirtyField]))
      onSubmit(changes)
    }
  }

  return (
    <form
      //@ts-expect-error no comment
      onSubmit={handleSubmit(onValidated, console.error)}
      style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 20 }}
    >
      <Title>Identité</Title>
      <Row>
        <InputTextControl control={control} name="lastName" label="Nom" error={errors.lastName} />
        <InputTextControl control={control} name="firstName" label="Prénom" error={errors.firstName} />
      </Row>

      <Title>Coordonnées</Title>
      <Row>
        <InputTextControl control={control} name="address" label="Adresse" error={errors.address} />
      </Row>

      <Row>
        <InputTextControl control={control} name="postalCode" label="Code Postal" error={errors.postalCode} />
        <InputTextControl control={control} name="city" label="Ville" error={errors.city} />
      </Row>

      <Row>
        <InputTextControl control={control} name="email" label="Email" error={errors.email} />
        <InputTextControl control={control} name="phone" label="Téléphone" error={errors.phone} />
      </Row>

      <Title>Informations juridique</Title>

      <Row>
        <InputTextControl control={control} name="companyName" label="Nom de l'entreprise" error={errors.companyName} />
        <InputTextControl control={control} name="companyType" label="Forme juridique" error={errors.companyType} />
        <SelectControl
          control={control}
          name="isSelfEmployed"
          label="Auto-entrepreneur"
          error={errors.isSelfEmployed}
          items={[
            { value: 'false', label: 'non' },
            { value: 'true', label: 'oui' },
          ]}
          defaultValue="false"
        />
      </Row>

      <Row>
        <InputTextControl control={control} name="vat" label="Numéro de TVA" error={errors.vat} />
        <InputTextControl control={control} name="shareCapital" label="Capital social" error={errors.shareCapital} />
        <InputTextControl control={control} name="rcs" label="RCS" error={errors.rcs} />
      </Row>

      <Row>
        <InputTextControl control={control} name="siren" label="SIREN" error={errors.siren} />
        <InputTextControl control={control} name="iban" label="IBAN" error={errors.iban} />
        <InputTextControl control={control} name="bic" label="BIC" error={errors.bic} />
      </Row>

      <Title>Autres infos</Title>
      <Row>
        <InputTextControl
          control={control}
          name="accountantEmail"
          label="Email comptable"
          error={errors.accountantEmail}
        />
        <InputTextControl control={control} name="careers" label="Métier" error={errors.careers} />
      </Row>
      <Row>
        <SelectControl
          control={control}
          name="source"
          label="Origine"
          error={errors.source}
          items={[{ value: null, label: '' }, ...Object.values(UserSource).map((value) => ({ value, label: value }))]}
          defaultValue={null}
        />
      </Row>

      <Button
        type="submit"
        disabled={isNoChange}
        style={{ backgroundColor: !isNoChange ? Theme.colors.blueArtizen : Theme.colors.blueGray, color: 'white' }}
      >
        Valider
      </Button>
    </form>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  ${Theme.font.size.xlarge};
  font-weight: 700;
  border-bottom: 1px solid;
`
