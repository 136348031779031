import { useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import { Theme } from 'theme/theme'
import { componentByMonth } from './dashboardFigures.helpers'
import DownloadButton from 'components/DownloadButton'

export default function DashboardInterventionsFigures(): ReactElement {
  const { dateStart, dateEnd, interventionStats, interventions } = useAppSelector((state) => state.dashboard)

  return (
    <>
      <tr>
        <td
          style={{
            fontWeight: 600,
            color: Theme.colors.blueArtizen,
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
          }}
        >
          Interventions
          <DownloadButton data={interventions} fileName="interventions" label={null} size="small" />
        </td>
      </tr>

      {!interventionStats ? null : (
        <>
          <tr>
            <td style={{ textAlign: 'right' }}>Nombre</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => (
                <td style={{ padding: 10, textAlign: 'center' }}>
                  <div>{interventionStats[year]?.[month]?.count}</div>
                </td>
              ),
            })}
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>User unique</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => (
                <td style={{ padding: 10, textAlign: 'center' }}>
                  <div>{interventionStats[year]?.[month]?.uniqueUserList.length}</div>
                </td>
              ),
            })}
          </tr>
        </>
      )}
    </>
  )
}
