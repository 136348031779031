import moment from 'moment'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { ClientClass, InterventionClass } from '@Arti-zen/package-backoffice'

export const definitionFields = (): ITableInfinite<InterventionClass['meilisearchDoc']>['definitionFields'] => {
  return [
    {
      indexName: 'id',
      label: 'id',
      isSortable: true,
      sortField: 'id',
    },
    {
      indexName: 'title',
      label: 'titre',
      isSortable: true,
      sortField: 'title',
    },
    {
      indexName: 'client',
      label: 'client intervention',
      format: ({ client }) => (client ? new ClientClass(client).displayFullName : ''),
    },
    {
      indexName: 'billingClient',
      label: 'client facturation',
      format: ({ billingClient }) => (billingClient ? new ClientClass(billingClient).displayFullName : ''),
    },
    {
      indexName: 'interventionStartDate',
      label: 'date de début',
      format: ({ interventionStartDate }) => moment(interventionStartDate).format('DD/MM/YYYY'),
      isSortable: true,
      sortField: 'interventionStartDate',
    },
    {
      indexName: 'status',
      label: 'statut',
      // format: ({ status, id }) => <QuotationListTableStatus status={status} id={id} />,
      styles: { overflow: 'visible' },
    },
    {
      indexName: 'hidden',
      label: 'supprimée',
    },
  ]
}

export const firstColumn: ITableInfinite<InterventionClass['meilisearchDoc']>['firstColumn'] = {
  format: (intervention, isRowHover) => <></>,
}
