import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { artisanListActions } from '../store/store.artisanList'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { ITableInfinite, Order } from 'components/table/infinite/InfiniteTable.interfaces'
import { UserArtizen } from '@Arti-zen/package-backoffice'
import SearchInputSync from 'components/forms/SearchInputSync'
import { useDebounce } from 'hooks/useDebounce'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import { pages } from 'navigation/pages'

const definitionFields = (navigate: NavigateFunction): ITableInfinite<UserArtizen>['definitionFields'] => {
  return [
    {
      indexName: 'id',
      label: 'Id',
      isSortable: true,
      sortField: 'id',
      format: ({ id }) => (
        <Link to={pages.connected.artisan.profile.navigate(id)} target="_blank">
          {id}
        </Link>
      ),
    },
    {
      indexName: 'lastName',
      label: 'Nom',
      isSortable: true,
      sortField: 'lastName',
      onClick: ({ id }) => {
        navigate(pages.connected.artisan.profile.navigate(id))
      },
    },
    {
      indexName: 'firstName',
      label: 'Prenom',
      isSortable: true,
      sortField: 'firstName',
      onClick: ({ id }) => {
        navigate(pages.connected.artisan.profile.navigate(id))
      },
    },
    {
      indexName: 'companyName',
      label: 'Nom entreprise',
      isSortable: true,
      sortField: 'companyName',
      onClick: ({ id }) => {
        navigate(pages.connected.artisan.profile.navigate(id))
      },
    },
    {
      indexName: 'email',
      label: 'Email',
      isSortable: true,
      sortField: 'email',
      onClick: ({ id }) => {
        navigate(pages.connected.artisan.profile.navigate(id))
      },
    },
    {
      indexName: 'isSubscribed',
      label: 'Abonné',
      format: ({ isSubscribed }) => (isSubscribed ? 'oui' : 'non'),
      onClick: ({ id }) => {
        navigate(pages.connected.artisan.profile.navigate(id))
      },
    },
    {
      indexName: 'subscriptionDate',
      label: 'Date abonnement',
      format: ({ subscriptionDate }) => (subscriptionDate ? new Date(subscriptionDate).toLocaleDateString() : ''),
      onClick: ({ id }) => {
        navigate(pages.connected.artisan.profile.navigate(id))
      },
    },
  ]
}

export default function ArtisanListHome(): ReactElement {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { artisanListDisplayed } = useAppSelector((state) => state.artisanList)
  document.title = 'ARTISANS'

  const fields = definitionFields(navigate)

  const [displayedFields, setDisplayedFields] = useState<(keyof UserArtizen)[]>(fields.map((def) => def.indexName))
  const [activeSort, setActiveSort] = useState<{ key: keyof UserArtizen; order: Order }[]>([
    { key: 'id', order: Order.ASC },
  ])

  const [search, setSearch] = useState<string>(null)
  const activeSearch = useDebounce(search, 200)

  useEffect(() => {
    dispatch(artisanListActions.getListBySearch(activeSearch))
  }, [activeSearch])

  const onSort = (key: keyof UserArtizen, order: Order) => {
    setActiveSort([{ key, order }])
    dispatch(artisanListActions.sort({ key, order }))
  }

  return (
    <Wrapper>
      <div style={{ width: '50%' }}>
        <SearchInputSync onChange={setSearch} placeholder="Taper votre recherche" displayCloseCross />
      </div>

      <TableInfinite<UserArtizen>
        displayedFields={displayedFields}
        setDisplayedFields={setDisplayedFields}
        definitionFields={fields}
        onSort={onSort}
        activeSorts={activeSort}
        data={artisanListDisplayed}
        nextPage={() => {}}
        isQueriedAllCompleted={true}
        firstColumn={{
          format: () => <></>,
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
