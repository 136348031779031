import { useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import { Theme } from 'theme/theme'
import { componentByMonth } from './dashboardFigures.helpers'

export default function DashboardOthersFigures(): ReactElement {
  const { dateStart, dateEnd, interventionStats, quotationStats, invoiceStats, artisanStats } = useAppSelector(
    (state) => state.dashboard
  )

  return (
    <>
      <tr style={{ fontWeight: 600, color: Theme.colors.blueArtizen }}>
        <td>Autres</td>
      </tr>
      {!interventionStats || !quotationStats || !invoiceStats ? null : (
        <tr>
          <td style={{ textAlign: 'right' }}>User Unique</td>
          {componentByMonth({
            dateStart,
            dateEnd,
            component: (year, month) => {
              const uniqInter = interventionStats[year]?.[month]?.uniqueUserList ?? []
              const uniqQuot = quotationStats[year]?.[month]?.uniqueUserList ?? []
              const uniqInvoice = invoiceStats[year]?.[month]?.uniqueUserList ?? []

              const merge = new Set([...uniqInter, ...uniqQuot, ...uniqInvoice])

              return (
                <td style={{ padding: 10, textAlign: 'center' }}>
                  <div>{merge.size}</div>
                </td>
              )
            },
          })}
        </tr>
      )}
      {!artisanStats ? null : (
        <>
          <tr>
            <td style={{ textAlign: 'right' }}>Abonnés</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => {
                return (
                  <td style={{ padding: 10, textAlign: 'center' }}>
                    <div>{artisanStats[year]?.[month]?.artisanSubscriptedList.length}</div>
                  </td>
                )
              },
            })}
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>Sous-comptes</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => {
                return (
                  <td style={{ padding: 10, textAlign: 'center' }}>
                    <div>{artisanStats[year]?.[month]?.artisanSubAccountList.length}</div>
                  </td>
                )
              },
            })}
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>Churn (nb)</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => {
                return (
                  <td style={{ padding: 10, textAlign: 'center' }}>
                    <div>{artisanStats[year]?.[month]?.artisanChurnedList.length}</div>
                  </td>
                )
              },
            })}
          </tr>
        </>
      )}
    </>
  )
}
