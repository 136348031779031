import { ReactElement, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { ITableInfinite, Order } from 'components/table/infinite/InfiniteTable.interfaces'
import { Facture, FactureErrorState, FactureState, UserArtizen } from '@Arti-zen/package-backoffice'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Format } from 'utils/format'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { TFactureFulFilled, factFulfilled } from 'utils/stats'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { artisanListActions } from 'modules/artisanList/store/store.artisanList'
import AutocompleteBase from 'components/forms/Autocomplete.base'
import CsvDownloadButton from 'react-json-to-csv'

type TStats = {
  totalMainHtCts: number
  totalSubHtCts: number
  totalPromoHtCts: number
  totalOptionsHtCts: number
  totalHtCts: number
  totalVariableHtCts: number
}

type TFactureFulFilledExtended = TFactureFulFilled & { artisan: UserArtizen }

const definitionFields = (
  navigate: NavigateFunction
): ITableInfinite<TFactureFulFilledExtended>['definitionFields'] => {
  return [
    {
      indexName: 'ref',
      label: 'Numéro de facture',
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'clientId',
      label: 'Arisan Id',
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'artisan',
      label: 'Artisan',
      format: ({ artisan }) => artisan?.companyName,
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'createdAt',
      label: 'date facture',
      format: ({ createdAt }) => Format.dateFr(createdAt),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'amount',
      label: 'Montant HT',
      format: ({ amount }) => Format.currency(amount, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'mainHtCts',
      label: 'Abonnement principal',
      format: ({ mainHtCts }) => Format.currency(mainHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'subHtCts',
      label: 'Sous-Comptes',
      format: ({ subHtCts }) => Format.currency(subHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'promoHtCts',
      label: 'Promo (abs)',
      format: ({ promoHtCts }) => Format.currency(promoHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'optionsHtCts',
      label: 'Options',
      format: ({ optionsHtCts }) => Format.currency(optionsHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
  ]
}

export default function DashboardMrr(): ReactElement {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { artisanListBase } = useAppSelector((state) => state.artisanList)

  const [dateStart, setDateStart] = useState<DateTime>(
    DateTime.now().setZone('Europe/Paris').minus({ month: 1 }).startOf('day')
  )
  const [dateEnd, setDateEnd] = useState<DateTime>(DateTime.now().setZone('Europe/Paris').endOf('day'))
  const [factures, setFactures] = useState<TFactureFulFilledExtended[]>([])
  const [companyIdSelected, setCompanyIdSelected] = useState<UserArtizen>()

  const [stats, setStats] = useState<TStats>({
    totalMainHtCts: 0,
    totalPromoHtCts: 0,
    totalSubHtCts: 0,
    totalOptionsHtCts: 0,
    totalHtCts: 0,
    totalVariableHtCts: 0,
  })

  const fields = definitionFields(navigate)

  const onSort = () => console.log('sort')

  const [displayedFields, setDisplayedFields] = useState<(keyof TFactureFulFilledExtended)[]>(
    fields.map((def) => def.indexName)
  )
  const [activeSort, setActiveSort] = useState<{ key: keyof Facture; order: Order }[]>([
    { key: 'createdAt', order: Order.DESC },
  ])

  const factureListFiltered = useMemo(() => {
    return factures.filter((fact) => (companyIdSelected ? +fact.clientId === +companyIdSelected : true))
  }, [companyIdSelected, factures])

  useEffect(() => {
    dispatch(artisanListActions.getAll())
  }, [])

  useEffect(() => {
    let isMount = true

    ApiArtizenBO.facturation
      .getAll({
        dateStart: dateStart.toString(),
        dateEnd: dateEnd.toString(),
      })
      .then((factureList) => {
        const factures = factureList.map((fact) => {
          return {
            ...factFulfilled(fact),
            artisan: artisanListBase.find((arti) => +arti.id === +fact.clientId),
          }
        })

        isMount && setFactures(factures)
      })

    return () => {
      isMount = false
    }
  }, [dateStart, dateEnd])

  useEffect(() => {
    setStats(
      factureListFiltered.reduce(
        (totaux, fact): TStats => {
          return {
            totalMainHtCts: totaux.totalMainHtCts + fact.mainHtCts,
            totalSubHtCts: totaux.totalSubHtCts + fact.subHtCts,
            totalPromoHtCts: totaux.totalPromoHtCts + fact.promoHtCts,
            totalOptionsHtCts: totaux.totalOptionsHtCts + fact.optionsHtCts,
            totalHtCts: totaux.totalHtCts + fact.totalHtCts,
            totalVariableHtCts: totaux.totalVariableHtCts + fact.variableHtCts,
          }
        },
        {
          totalMainHtCts: 0,
          totalPromoHtCts: 0,
          totalSubHtCts: 0,
          totalOptionsHtCts: 0,
          totalHtCts: 0,
          totalVariableHtCts: 0,
        }
      )
    )
  }, [factureListFiltered])

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 50, marginTop: 50 }}>
        <DatePicker label="Date de début" value={dateStart} onChange={setDateStart} timezone="Europe/Paris" />

        <DatePicker label="Date de fin" value={dateEnd} onChange={setDateEnd} />

        <AutocompleteBase
          label="Entrepise"
          items={artisanListBase
            .filter((artisan) => !artisan.parentId)
            .map((arti) => ({ value: arti.id, label: `${arti.id} - ${arti?.companyName ?? ''}` }))}
          onChange={setCompanyIdSelected}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
        <DisplayStat name="Abonnements HT" value={Format.currency(stats.totalMainHtCts / 100, 2)} />
        <DisplayStat name="Comptes Salariés HT" value={Format.currency(stats.totalSubHtCts / 100, 2)} />
        <DisplayStat name="Options HT" value={Format.currency(stats.totalOptionsHtCts / 100, 2)} />
        <DisplayStat
          isTotal
          name="Total HT Brut"
          value={Format.currency((stats.totalMainHtCts + stats.totalSubHtCts + stats.totalOptionsHtCts) / 100, 2)}
        />
        <DisplayStat name="Promos HT" value={Format.currency(-stats.totalPromoHtCts / 100, 2)} />
        <DisplayStat
          isTotal
          name="Total HT Net (MRR)"
          value={Format.currency(
            (stats.totalMainHtCts + stats.totalSubHtCts + stats.totalOptionsHtCts - stats.totalPromoHtCts) / 100,
            2
          )}
        />
        <DisplayStat name="Variable HT" value={Format.currency(stats.totalVariableHtCts / 100, 2)} />
        <DisplayStat isTotal name="Total HT Net" value={Format.currency(stats.totalHtCts / 100, 2)} />
        <DisplayStat name="Nombre factures" value={String(factureListFiltered.length)} />
        <DisplayStat
          name="Facture HT moyenne"
          value={Format.currency(stats.totalHtCts / 100 / factureListFiltered.length, 2)}
        />
      </div>

      <div>
        <CsvDownloadButton data={factureListFiltered} title="missing" filename="factures" style={{ margin: 5 }}>
          Télécharger les factures
        </CsvDownloadButton>
      </div>

      <WrapperContent>
        <TableInfinite<TFactureFulFilledExtended>
          displayedFields={displayedFields}
          setDisplayedFields={setDisplayedFields}
          definitionFields={fields}
          onSort={onSort}
          activeSorts={activeSort}
          data={factureListFiltered}
          nextPage={() => {}}
          isQueriedAllCompleted={true}
          firstColumn={{
            format: () => <></>,
          }}
        />
      </WrapperContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`

const DisplayStat = ({ name, value, isTotal = false }: { name: string; value: string; isTotal?: boolean }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        ...(isTotal ? { fontWeight: 600, borderTop: 'solid 1px' } : {}),
      }}
    >
      <div style={{ display: 'flex', flex: 3 }}>{name}</div>
      <div style={{ display: 'flex', flex: 1 }}>{value}</div>
    </div>
  )
}
