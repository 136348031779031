import { ReactElement } from 'react'
import { Route } from 'react-router-dom'
import { pages } from 'navigation/pages'
import FactureListHome from '../components/FactureList/FactureList'
import FactureListLayout from './FactureListLayout'
import PaymentOrphanList from '../components/PaymentOrphanList/PaymentOrphanList'
import Payment from '../components/Payment'
import FactureDisplay from '../components/Facture/Facture'
import FactureMissingList from '../components/FactureMissingList/FactureMissingList'
import UnpaidByUserList from '../components/UnpaidByUserList/UnpaidByUserList'
import { Roles } from '@Arti-zen/package-backoffice'
import { RouteProtected } from 'navigation/navigation.helper'

export default function FacturationListRouter(): ReactElement {
  return (
    <Route
      path={pages.connected.factureList.root}
      element={RouteProtected(<FactureListLayout />, [Roles.SUPER_ADMIN, Roles.ADMIN])}
    >
      <Route path={pages.connected.factureList.factures.name} element={<FactureListHome />} />
      <Route path={pages.connected.factureList.factureMissingList.name} element={<FactureMissingList />} />
      <Route path={pages.connected.factureList.paymentOrphanList.name} element={<PaymentOrphanList />} />
      <Route path={pages.connected.factureList.payment.name} element={<Payment />} />
      <Route path={pages.connected.factureList.facture.name} element={<FactureDisplay />} />
      <Route path={pages.connected.factureList.unpaidByUserList.name} element={<UnpaidByUserList />} />
    </Route>
  )
}
