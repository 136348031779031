import { DateTime, Interval } from 'luxon'
import { ReactElement } from 'react'

export const componentByMonth = ({
  dateStart,
  dateEnd,
  component,
}: {
  dateStart: DateTime
  dateEnd: DateTime
  component: (year: number, month: number) => ReactElement
}) =>
  Interval.fromDateTimes(dateStart, dateEnd)
    .splitBy({ month: 1 })
    .map((val) => ({ year: val.start.year, month: val.start.month }))
    .map(({ year, month }) => component(year, month))
    .flat()

export const actionByMonth = ({
  dateStart,
  dateEnd,
  action,
}: {
  dateStart: DateTime
  dateEnd: DateTime
  action: (year: number, month: number) => any
}): { [year: number]: { [month: number]: any } } => {
  let result = {}
  Interval.fromDateTimes(dateStart, dateEnd)
    .splitBy({ month: 1 })
    .map((val) => ({ year: val.start.year, month: val.start.month }))
    .forEach(({ year, month }) => {
      if (!result[year]) result[year] = []
      result[year][month] = action(year, month)
    })

  return result
}

export const actionByMonth2 = <T>({
  dateStart,
  dateEnd,
  action,
}: {
  dateStart: DateTime
  dateEnd: DateTime
  action: (year: number, month: number) => any
}): { [year: number]: { [month: number]: T } } => {
  let result = {}
  Interval.fromDateTimes(dateStart, dateEnd)
    .splitBy({ month: 1 })
    .map((val) => ({ year: val.start.year, month: val.start.month }))
    .forEach(({ year, month }) => {
      if (!result[year]) result[year] = []
      result[year][month] = action(year, month)
    })

  return result
}
