import { Roles } from '@Arti-zen/package-backoffice'
import { useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'

export const RouteProtected = (component: ReactElement, accessRoles: Roles[]): ReactElement => {
  const { user } = useAppSelector((state) => state.auth)
  const role = user?.role

  if (accessRoles.includes(role)) {
    return component
  } else {
    return <>No Access</>
  }
}
