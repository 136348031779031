import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { useInfiniteTableContext } from '../InfiniteTable.context'
import { Theme } from 'theme/theme'
import OutsideClickHandler from '../OutsideClickHandler'
import { Button } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

export default function ButtonSelectFields<T>(): ReactElement {
  const { definitionFields, displayedFields, setDisplayedFields } = useInfiniteTableContext<T>()
  const [open, setOpen] = useState(false)

  const addOrRemoveField = (indexName: keyof T) => {
    if (displayedFields.includes(indexName)) {
      setDisplayedFields(displayedFields.filter((field) => field !== indexName))
    } else {
      setDisplayedFields([...displayedFields, indexName])
    }
  }

  return (
    <Wrapper>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <Button onClick={() => setOpen(!open)} />
        {open ? (
          <ModalOptions>
            {definitionFields.map((field) => (
              <Checkbox
                key={`radio-${String(field.indexName)}`}
                onChange={() => addOrRemoveField(field.indexName)}
                checked={displayedFields.includes(field.indexName)}
              />
            ))}
          </ModalOptions>
        ) : null}
      </OutsideClickHandler>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  z-index: 20;
`

const ModalOptions = styled.div`
  font-family: ${Theme.font.family};
  font-weight: 400;
  position: absolute;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;
  left: 5px;
  gap: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow:
    0px 10px 10px rgba(0, 0, 0, 0.1),
    0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 210px;
`
