import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IDashboardState } from './store.dashboard.interfaces'
import * as thunks from './store.dashboard.thunks'
import { DateTime } from 'luxon'

const initialState: IDashboardState = {
  dateStart: DateTime.now().setZone('Europe/Paris').minus({ year: 1 }).startOf('month'),
  dateEnd: DateTime.now().setZone('Europe/Paris').endOf('month'),
  interventions: [],
  quotations: [],
  invoices: [],
  artisans: [],
  artisanList: null,
  partnerStatList: [],
  userLinkedList: [],
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    logout() {
      return initialState
    },
    setDateStart(state, action: PayloadAction<DateTime>) {
      state.dateStart = action.payload.setZone('Europe/Paris').startOf('day')
    },
    setDateEnd(state, action: PayloadAction<DateTime>) {
      state.dateEnd = action.payload.setZone('Europe/Paris').endOf('day')
    },
    setStatsReady(state, action: PayloadAction<{ statName: string }>) {
      state[`${action.payload.statName}`] = true
    },
  },
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const dashboardActions = { ...dashboardSlice.actions, ...thunks.asyncActions }
export default dashboardSlice.reducer
