import { ReactElement } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Order } from '../InfiniteTable.interfaces'

interface ISortableButton {
  order: Order | null
}

export default function SortableButton({ order }: Readonly<ISortableButton>): ReactElement {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ color: !order ? '#C1C1C1' : order === Order.ASC ? '#1EDC98' : 'rgba(193, 193, 193, 0.3)' }}>
        <ArrowDropUpIcon fontSize="medium" viewBox="0 -7 24 24" />
      </span>
      <span style={{ color: !order ? '#C1C1C1' : order === Order.DESC ? '#1EDC98' : 'rgba(193, 193, 193, 0.3)' }}>
        <ArrowDropDownIcon fontSize="medium" viewBox="0 7 24 24" />
      </span>
    </div>
  )
}
