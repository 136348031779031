import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { Control, Controller, FieldError, Path } from 'react-hook-form'
import styled from 'styled-components'
import AutocompleteBase, { TAutocompleteBase } from './Autocomplete.base'

type TAutocompleteControl<T> = Omit<TAutocompleteBase, 'onChange'> & {
  control: Control<T>
  name: Path<T>
  label?: string
  error?: FieldError
  required?: boolean
  defaultValue?: any
}

export default function AutocompleteControl<T>({
  control,
  name,
  label,
  items,
  required = false,
  defaultValue,
}: Readonly<TAutocompleteControl<T>>): ReactElement {
  return (
    <Wrapper>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label={label}
            labelPlacement="top"
            required={required}
            control={<AutocompleteBase items={items} onChange={field.onChange} />}
          />
        )}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const ErrorWrapper = styled.div`
  color: red;
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
`

const Label = styled.span``
