import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { Quotation, QuotationClass } from '@Arti-zen/package-backoffice'
import { IQuotationState } from './store.quotation.interfaces'

const get = {
  action: createAsyncThunk('quotation/get', async (id: Quotation['id']) => {
    return ApiArtizenBO.quotations.getOne(id)
  }),
  reducer: (builder: ActionReducerMapBuilder<IQuotationState>) => {
    builder
      .addCase(get.action.pending, (state) => {
        state.isLoading = true
      })
      .addCase(get.action.fulfilled, (state, action) => {
        state.quotation = new QuotationClass(action.payload)
        state.isLoading = false
      })
      .addCase(get.action.rejected, (state, _action) => {
        state.quotation = null
        state.isLoading = false
      })
  },
}

const generatePdf = {
  action: createAsyncThunk('quotation/generate', async (id: Quotation['id']) => {
    return ApiArtizenBO.quotations.pdfGenerate(id)
  }),
  reducer: (builder: ActionReducerMapBuilder<IQuotationState>) => {
    builder
      .addCase(generatePdf.action.pending, (state) => {
        state.quotation = null
        state.isLoading = true
      })
      .addCase(generatePdf.action.fulfilled, (state, action) => {
        state.quotation = new QuotationClass(action.payload)
        state.isLoading = false
      })
      .addCase(generatePdf.action.rejected, (state, _action) => {
        state.quotation = null
        state.isLoading = false
      })
  },
}

export const asyncActions = {
  get: get.action,
  generatePdf: generatePdf.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IQuotationState>) => {
  get.reducer(builder)
  generatePdf.reducer(builder)
}
