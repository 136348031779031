import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect } from 'react'
import styled from 'styled-components'
import { dashboardActions } from '../../store/store.dashboard'
import { DatePicker } from '@mui/x-date-pickers'
import { componentByMonth } from './dashboardFigures.helpers'
import DashboardInterventionsFigures from './DashboardFigures.interventions'
import DashboardQuotationsFigures from './DashboardFigures.quotations'
import DashboardInvoicesFigures from './DashboardFigures.invoices'
import DashboardOthersFigures from './DashboardFigures.others'

export default function DashboardFigures(): ReactElement {
  const dispatch = useAppDispatch()
  const { dateStart, dateEnd } = useAppSelector((state) => state.dashboard)

  useEffect(() => {
    dispatch(dashboardActions.getArtisans())
  }, [])

  useEffect(() => {
    dispatch(dashboardActions.getInterventions())
    dispatch(dashboardActions.getQuotations())
    dispatch(dashboardActions.getInvoices())
    dispatch(dashboardActions.getArtisans())
  }, [dateStart, dateEnd])

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 50, marginTop: 50 }}>
        <DatePicker
          label="Date de début"
          value={dateStart}
          onChange={(e) => dispatch(dashboardActions.setDateStart(e))}
          timezone="Europe/Paris"
        />

        <DatePicker label="Date de fin" value={dateEnd} onChange={(e) => dispatch(dashboardActions.setDateEnd(e))} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 50 }}>
        <table style={{ border: 'solid 1px', margin: 20 }}>
          <tr>
            <th></th>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => <th style={{ padding: 10, textAlign: 'center' }}>{year + '/' + month}</th>,
            })}
          </tr>

          <DashboardInterventionsFigures />
          <DashboardQuotationsFigures />
          <DashboardInvoicesFigures />
          <DashboardOthersFigures />
        </table>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
