import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { IAuthState } from './store.auth.interfaces'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { RootState } from 'store/store'

const login = {
  action: createAsyncThunk(
    'auth/login',
    async ({ email, password }: { email: string; password: string }, _thunkApi) => {
      return ApiArtizenBO.users.login({ email, password })
    }
  ),
  reducer: (builder: ActionReducerMapBuilder<IAuthState>) => {
    builder
      .addCase(login.action.fulfilled, (state, action) => {
        state.user = action.payload
        ApiArtizenBO.setToken(action.payload.token)
      })
      .addCase(login.action.rejected, (state, _action) => {
        state.user = undefined
      })
  },
}

const me = {
  action: createAsyncThunk('auth/me', async (_, thunkApi) => {
    const { auth } = thunkApi.getState() as RootState

    if (auth?.user?.token) {
      ApiArtizenBO.setToken(auth.user.token)
      return ApiArtizenBO.users.me()
    } else {
      throw new Error('no user logged yet')
    }
  }),
  reducer: (builder: ActionReducerMapBuilder<IAuthState>) => {
    builder
      .addCase(me.action.fulfilled, (state, action) => {
        state.user = { ...action.payload, token: state.user.token }
      })
      .addCase(me.action.rejected, (state, _action) => {
        console.log('rejected', _action.error.message)
        state.user = undefined
      })
  },
}

export const asyncActions = {
  login: login.action,
  me: me.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IAuthState>) => {
  login.reducer(builder)
  me.reducer(builder)
}
