export const dashboardPages = {
  root: {
    name: '/dashboard',
    navigate: () => `/dashboard`,
  },
  figures: {
    name: '/dashboard/figures',
    navigate: () => `/dashboard/figures`,
  },
  mrr: {
    name: '/dashboard/mrr',
    navigate: () => `/dashboard/mrr`,
  },
  mrrOneShot: {
    name: '/dashboard/mrrOneShot',
    navigate: () => `/dashboard/mrrOneShot`,
  },
  figuresPartner: {
    name: '/dashboard/figuresPartner',
    navigate: () => `/dashboard/figuresPartner`,
  },
}
