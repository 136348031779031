import { TCodePromo, TCodePromoUser, UserArtizen } from '@Arti-zen/package-backoffice'
import { Alert, Button } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import InputDateControl from 'components/forms/InputDate.control'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { Format } from 'utils/format'
import AutocompleteControl from 'components/forms/Autocomplete.control'
import CodePromoCreateForm from './CodePromoCreate.form'
import { DateTime } from 'luxon'

interface IArtisanForm {
  artisanId: UserArtizen['id']
  onSubmit: (data: TCodePromoUser) => void
}

export default function ArtisanCodePromoForm({ artisanId, onSubmit }: Readonly<IArtisanForm>): ReactElement {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<TCodePromoUser>({
    reValidateMode: 'onChange',
  })

  const promoCodeId = watch('promoCodeId')
  const promoCode = watch('promoCode')

  const [selectCodePromoList, setSelectCodePromoList] = useState<TCodePromo[]>([])

  const [creationMode, setCreationMode] = useState(false)

  useEffect(() => {
    let isMount = true
    ApiArtizenBO.codePromo.getAll().then((codePromoList) => isMount && setSelectCodePromoList(codePromoList))

    return () => {
      isMount = false
    }
  }, [])

  useEffect(() => {
    const promoCode = selectCodePromoList?.find((codePromo) => codePromo.id === promoCodeId)
    setValue('promoCode', promoCode)
    setValue('promoStart', DateTime.now().toISODate())
    setValue(
      'promoEnd',
      DateTime.now()
        .plus({ months: promoCode?.duration ?? 12 })
        .minus({ days: 1 })
        .toISODate()
    )
  }, [promoCodeId])

  const onValidated = async (data: TCodePromoUser) => {
    try {
      const codePromoUser: TCodePromoUser = await ApiArtizenBO.codePromo.addCodePromoToUser({
        codePromoIdToLink: +data.promoCodeId,
        userId: artisanId,
        startDate: data.promoStart,
        endDate: data.promoEnd,
      })
      onSubmit(codePromoUser)
    } catch (error) {
      window.alert(error?.message)
    }
  }

  const PromoCodeInfo = useCallback(() => {
    return promoCode ? <DisplayPromoCodeInfo promoCode={promoCode} /> : <></>
  }, [promoCode])

  return (
    <>
      <div style={{ display: creationMode ? 'flex' : 'none' }}>
        <CodePromoCreateForm
          onCreated={(codePromo) => {
            setSelectCodePromoList([codePromo, ...selectCodePromoList])
            setValue('promoCodeId', codePromo.id)
            setCreationMode(false)
          }}
        />
      </div>

      <form
        onSubmit={handleSubmit(onValidated, console.error)}
        style={{ display: creationMode ? 'none' : 'flex', width: '100%', flexDirection: 'column', gap: 20 }}
      >
        <Row>
          <AutocompleteControl
            control={control}
            name="promoCodeId"
            label="Code promo Id"
            error={errors.promoCodeId}
            items={selectCodePromoList?.map((codePromo) => ({ value: codePromo.id, label: codePromo.code }))}
          />
          <Button
            onClick={() => setCreationMode(true)}
            style={{
              display: 'flex',
              alignSelf: 'center',
              backgroundColor: Theme.colors.blueArtizen,
              color: 'white',
              width: 200,
            }}
          >
            Créer un nouveau Code promo
          </Button>
        </Row>
        <Row>
          <PromoCodeInfo />
        </Row>
        <Row>
          <InputDateControl control={control} name="promoStart" label="Date de début" error={errors.promoStart} />
          <InputDateControl control={control} name="promoEnd" label="Date de fin" error={errors.promoEnd} />
        </Row>

        <Button
          type="submit"
          style={{
            display: 'flex',
            alignSelf: 'center',
            backgroundColor: Theme.colors.blueArtizen,
            color: 'white',
            width: 200,
          }}
        >
          Valider
        </Button>
      </form>
    </>
  )
}

const DisplayPromoCodeInfo = ({ promoCode }: { promoCode: TCodePromo }): ReactElement => {
  return (
    <Row>
      <div>Code : {promoCode.code}</div>
      <div>
        Valeur:{' '}
        {promoCode.absoluteDiscount ? Format.currency(promoCode.absoluteDiscount) : promoCode.relativeDiscount + '%'}
      </div>
      <div>Durée conseillée : {promoCode.duration} mois</div>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`
