import { useAppSelector } from 'hooks/useStore'
import { ReactElement, useMemo } from 'react'
import { Theme } from 'theme/theme'
import { componentByMonth } from '../DashboardFigures/dashboardFigures.helpers'
import { Format } from 'utils/format'

const keys = ['quotation', 'invoice', 'cart']

export default function DashboardProductOrders(): ReactElement {
  const { dateStart, dateEnd, partnerStats } = useAppSelector((state) => state.dashboard)
  console.log(partnerStats)

  return (
    <>
      {!partnerStats
        ? null
        : keys.map((key) => (
            <>
              <tr style={{ fontWeight: 600, color: Theme.colors.blueArtizen }}>
                <td>{key}</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'right' }}>Nombre</td>
                {componentByMonth({
                  dateStart,
                  dateEnd,
                  component: (year, month) => (
                    <td style={{ padding: 10, textAlign: 'center' }}>
                      <div>{partnerStats[year]?.[month]?.[key]?.count}</div>
                    </td>
                  ),
                })}
              </tr>
              <tr>
                <td style={{ textAlign: 'right' }}>User Unique</td>
                {componentByMonth({
                  dateStart,
                  dateEnd,
                  component: (year, month) => (
                    <td style={{ padding: 10, textAlign: 'center' }}>
                      <div>{partnerStats[year]?.[month]?.[key]?.uniqueUserList.length}</div>
                    </td>
                  ),
                })}
              </tr>
              <tr>
                <td style={{ textAlign: 'right' }}>Nb produit</td>
                {componentByMonth({
                  dateStart,
                  dateEnd,
                  component: (year, month) => (
                    <td style={{ padding: 10, textAlign: 'center' }}>
                      <div>{partnerStats[year]?.[month]?.[key]?.nbProducts}</div>
                    </td>
                  ),
                })}
              </tr>
              <tr>
                <td style={{ textAlign: 'right' }}>CA HT</td>
                {componentByMonth({
                  dateStart,
                  dateEnd,
                  component: (year, month) => (
                    <td style={{ padding: 10, textAlign: 'center' }}>
                      <div>{Format.currency((partnerStats[year]?.[month]?.[key]?.totalHTcts ?? 0) / 100, 0)}</div>
                    </td>
                  ),
                })}
              </tr>
            </>
          ))}
    </>
  )
}
