import { ClientClass, IClientsGet } from '@Arti-zen/package-backoffice'

export interface IClientListState {
  isLoading: boolean
  errorType?: ClientListStoreErrorType
  isQueriedAllCompleted: boolean
  clients: ClientClass['meilisearchDoc'][]
  filters?: IClientsGet
}

export enum ClientListStoreErrorType {
  network = 'network',
  dbLinkedDocument = 'dbLinkedDocument',
  sendByEmail = 'sendByEmail',
}
