import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.facturation.thunks'
import { EPaymentProgressStatus, IFacturationListState } from './store.facturation.interfaces'
import { DateTime } from 'luxon'
import { FactureErrorState, FactureState } from '@Arti-zen/package-backoffice'
import { applyFilters } from './store.facturation.helpers'

const initialState: IFacturationListState = {
  dateStart: DateTime.now().setZone('Europe/Paris').minus({ month: 1 }).startOf('day'),
  dateEnd: DateTime.now().setZone('Europe/Paris').endOf('day'),
  stateList: Object.values(FactureState),
  errorList: Object.values(FactureErrorState),
  paymentStatusList: Object.values(EPaymentProgressStatus),
  factures: [],
  displayedFactures: [],
  paymentOrphanList: [],
  factureMissingList: [],
}

const factureListSlice = createSlice({
  name: 'factureList',
  initialState,
  reducers: {
    logout() {
      return initialState
    },
    setDateStart(state, action: PayloadAction<DateTime>) {
      state.dateStart = action.payload.setZone('Europe/Paris').startOf('day')
    },
    setDateEnd(state, action: PayloadAction<DateTime>) {
      state.dateEnd = action.payload.setZone('Europe/Paris').endOf('day')
    },
    switchError(state, action: PayloadAction<FactureErrorState>) {
      if (state.errorList.includes(action.payload)) {
        state.errorList = state.errorList.filter((state) => state !== action.payload)
      } else {
        state.errorList = [...state.errorList, action.payload]
      }

      state.displayedFactures = applyFilters(state)
    },
    switchState(state, action: PayloadAction<FactureState>) {
      if (state.stateList.includes(action.payload)) {
        state.stateList = state.stateList.filter((state) => state !== action.payload)
      } else {
        state.stateList = [...state.stateList, action.payload]
      }

      state.displayedFactures = applyFilters(state)
    },
    switchPaymentStatus(state, action: PayloadAction<EPaymentProgressStatus>) {
      if (state.paymentStatusList.includes(action.payload)) {
        state.paymentStatusList = state.paymentStatusList.filter((state) => state !== action.payload)
      } else {
        state.paymentStatusList = [...state.paymentStatusList, action.payload]
      }

      state.displayedFactures = applyFilters(state)
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
      state.displayedFactures = applyFilters(state)
    },
  },
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const factureListActions = { ...factureListSlice.actions, ...thunks.asyncActions }
export default factureListSlice.reducer
