import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

//STORES
import AuthReducer from 'modules/auth/store/store.auth'
import ArtisanListReducer from 'modules/artisanList/store/store.artisanList'
import ArtisanReducer from 'modules/artisan/store/store.artisan'
import QuotationListReducer from 'modules/quotationList/store/store.quotationList.reducer'
import InvoiceListReducer from 'modules/invoiceList/store/store.invoiceList.reducer'
import InterventionListReducer from 'modules/interventionList/store/store.interventionList.reducer'
import ClientListReducer from 'modules/clientList/store/store.clientList.reducer'
import QuotationReducer from 'modules/quotation/store/store.quotation'
import InvoiceReducer from 'modules/invoice/store/store.invoice'
import DashboardReducer from 'modules/dashboard/store/store.dashboard'
import FacturationListReducer from 'modules/facturation/store/store.facturation'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth'],
}

const rootReducer = combineReducers({
  auth: AuthReducer,
  artisanList: ArtisanListReducer,
  artisan: ArtisanReducer,
  quotationList: QuotationListReducer,
  quotation: QuotationReducer,
  invoiceList: InvoiceListReducer,
  invoice: InvoiceReducer,
  interventionList: InterventionListReducer,
  clientList: ClientListReducer,
  dashboard: DashboardReducer,
  facturation: FacturationListReducer,
})

export const Store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }),
})

export const persistor = persistStore(Store)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof Store.dispatch
