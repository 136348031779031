import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { NavLink } from 'react-router-dom'
import { pages } from 'navigation/pages'
import { TUnpaidByUser } from './UnpaidByUserList'
import { Format } from 'utils/format'

export const definitionFields = (): ITableInfinite<TUnpaidByUser>['definitionFields'] => {
  return [
    {
      indexName: 'user',
      label: 'artisan',
      format: ({ user }) => (
        <NavLink to={pages.connected.artisan.facturation.navigate(user.id)} target="_blank">
          {user.id} - {user.email}
        </NavLink>
      ),
    },
    {
      indexName: 'totalTTC',
      label: 'Total TTC',
      format: ({ totalTTC }) => <>{Format.currency(totalTTC)}</>,
    },
    {
      indexName: 'nbUnpaid',
      label: 'Nombre de factures',
    },
  ]
}
