import { FormControlLabel, MenuItem, Select } from '@mui/material'
import { ReactElement } from 'react'

interface IArtisanForm {
  label?: string
  required?: boolean
  items: { value: any; label: string }[]
  defaultValue: any
  onChange: (value: any) => void
}

export default function SelectBase({
  label,
  items,
  required = false,
  defaultValue,
  onChange,
}: Readonly<IArtisanForm>): ReactElement {
  return (
    <Select
      onChange={(e) => onChange(e.target.value)}
      labelId={label + 'control'}
      id={label}
      label={label}
      required={required}
      margin="none"
      defaultValue={defaultValue}
    >
      {items.map(({ value, label }) => (
        <MenuItem key={label + value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}
