import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.interventionList.thunks'
import { IInterventionListState } from './store.interventionList.interfaces'

const initialState: IInterventionListState = {
  isLoading: false,
  isQueriedAllCompleted: false,
  interventions: [],
}

const interventionListSlice = createSlice({
  name: 'interventionList',
  initialState,
  reducers: {},
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const interventionListActions = { ...interventionListSlice.actions, ...thunks.asyncActions }
export default interventionListSlice.reducer
