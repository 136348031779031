import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { ClientClass } from '@Arti-zen/package-backoffice'

export const definitionFields = (): ITableInfinite<ClientClass['meilisearchDoc']>['definitionFields'] => {
  return [
    {
      indexName: 'id',
      label: 'id',
      isSortable: true,
      sortField: 'id',
    },
    {
      indexName: 'lastName',
      label: 'Nom',
      isSortable: true,
      sortField: 'lastName',
    },
    {
      indexName: 'firstName',
      label: 'Prénom',
      isSortable: true,
      sortField: 'firstName',
    },
    {
      indexName: 'archived',
      label: 'Archivé',
    },
  ]
}

export const firstColumn: ITableInfinite<ClientClass['meilisearchDoc']>['firstColumn'] = {
  format: (client, isRowHover) => <></>,
}
