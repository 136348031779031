import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.artisanList.thunks'
import { IArtisanListState } from './store.artisanList.interfaces'
import { UserArtizen } from '@Arti-zen/package-backoffice'
import { Order } from 'components/table/infinite/InfiniteTable.interfaces'

const searchFields: (keyof UserArtizen)[] = ['id', 'lastName', 'firstName', 'email', 'companyName']

const initialState: IArtisanListState = {
  artisanListBase: [],
  artisanListDisplayed: [],
  activeSort: {
    key: 'id',
    order: Order.ASC,
  },
}

const artisanListSlice = createSlice({
  name: 'artisanList',
  initialState,
  reducers: {
    logout() {
      return initialState
    },
    sort(state, { payload }: { payload: { key: keyof UserArtizen; order: Order } }) {
      state.activeSort = payload

      state.artisanListDisplayed = applySort(payload, state.artisanListDisplayed)
    },
    search(state, { payload }: { payload: string }) {
      let searchArray = []

      if (payload) {
        const valuesToTest = payload?.split(' ')?.filter((val) => val)

        searchArray = state.artisanListBase.filter((artisan) =>
          valuesToTest?.every((test) =>
            searchFields.some((field) => {
              const valueTested = artisan[String(field)]?.toString().toLowerCase()
              return valueTested?.includes(test)
            })
          )
        )
      } else {
        searchArray = state.artisanListBase
      }

      state.artisanListDisplayed = applySort(state.activeSort, searchArray)
    },
  },
  extraReducers: (builder) => thunks.extraReducers(builder),
})

const applySort = (activeSort: IArtisanListState['activeSort'], list: IArtisanListState['artisanListDisplayed']) => {
  const { key, order } = activeSort

  return [...list].sort((a, b) => {
    if (order === Order.ASC) {
      return a[key] > b[key] ? 1 : -1
    } else {
      return a[key] < b[key] ? 1 : -1
    }
  })
}

export const artisanListActions = { ...artisanListSlice.actions, ...thunks.asyncActions }
export default artisanListSlice.reducer
