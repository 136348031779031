import { ReactElement, useEffect, useState } from 'react'
import { TInvoiceQuotationMissing } from '@Arti-zen/package-backoffice'
import { ApiArtizenBO } from 'utils/artizenConnector'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'

export default function InvoiceQuotationMissingList(): ReactElement {
  const [invoiceQuotationMissingList, setInvoiceQuotationMissingList] = useState<
    (TInvoiceQuotationMissing & { id: number })[]
  >([])

  useEffect(() => {
    let isMount = true
    ApiArtizenBO.invoices
      .getInvoiceQuotationMissingList()
      .then(
        (list) =>
          isMount &&
          setInvoiceQuotationMissingList(
            list.map((item) => ({ ...item, id: item.invoiceId })).sort((a, b) => b.quotationId - a.quotationId)
          )
      )
      .catch(console.error)

    return () => {
      isMount = false
    }
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, fontWeight: 700, marginBottom: 20 }}>
        <div>Nb invoices: {invoiceQuotationMissingList.length}</div>
        <div>Nb quot: {new Set(invoiceQuotationMissingList.map((q) => q.quotationId)).size}</div>
      </div>

      <TableInfinite<TInvoiceQuotationMissing & { id: number }>
        data={invoiceQuotationMissingList}
        isQueriedAllCompleted={true}
        definitionFields={definitionFields()}
        displayedFields={definitionFields().map((def) => def.indexName)}
        nextPage={null}
      />
    </div>
  )
}

const definitionFields = (): ITableInfinite<TInvoiceQuotationMissing & { id: number }>['definitionFields'] => {
  return [
    {
      indexName: 'id',
      label: 'invoiceId',
      isSortable: true,
      sortField: 'id',
    },
    {
      indexName: 'invoiceRef',
      label: 'invoiceRef',
    },
    {
      indexName: 'quotationId',
      label: 'quotationId',
    },
    {
      indexName: 'invoiceCreationDate',
      label: 'invoiceCreationDate',
    },
    {
      indexName: 'invoicePdfUrl',
      label: 'invoicePdfUrl',
      format: ({ invoicePdfUrl: val }) =>
        val ? (
          <a target="_blank" href={val}>
            Voir la facture
          </a>
        ) : (
          '-'
        ),
    },
  ]
}
