import { Roles } from '@Arti-zen/package-backoffice'
import { useAppSelector } from 'hooks/useStore'
import { pages } from 'navigation/pages'
import { ReactElement } from 'react'
import { NavLink, Navigate, Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { Theme } from 'theme/theme'

export default function LayoutConnected(): ReactElement {
  const { user } = useAppSelector((state) => state.auth)

  if (!user) return <Navigate to="/login" />

  return (
    <Wrapper>
      <Nav>
        <NavLink to={pages.connected.artisanList.root}>Artisans</NavLink>
        {[Roles.SUPER_ADMIN, Roles.ADMIN].includes(user.role) ? (
          <>
            <NavLink to={pages.connected.dashboard.root.name}>Dashboard</NavLink>
            <NavLink to={pages.connected.factureList.root}>Facturation</NavLink>
            <NavLink to={pages.connected.support.root}>Support</NavLink>
          </>
        ) : null}
        <NavLink to="/logout">Se déconnecter</NavLink>
      </Nav>
      <WrapperContent>
        <Outlet />
      </WrapperContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
  overflow: auto;
`

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding-top: 10px;
  gap: 20px;
  height: 45px;

  background-color: ${Theme.colors.blueArtizen};
  a {
    color: white;
    font-weight: 700;
    text-decoration: none;
  }

  a.active {
    border-bottom: solid 4px white;
  }
`
