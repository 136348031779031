import { ReactElement } from 'react'
import styled from 'styled-components'
import SortableButton from './SortableButton'
import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import ButtonSelectFields from './ButtonSelectField'
import { useInfiniteTableContext } from '../InfiniteTable.context'
import { Theme } from 'theme/theme'
import { Order } from '../InfiniteTable.interfaces'

export default function TableHeaders<T>(): ReactElement {
  const { definitionFields, onSort, activeSorts, setDisplayedFields, displayedFields } = useInfiniteTableContext<T>()

  const sensors = useSensors(useSensor(PointerSensor))

  const currentSort = activeSorts ? activeSorts[0] : null
  const { order, key } = currentSort || {}

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={(event) => handleDragEnd({ event, displayedFields, setDisplayedFields, onSort })}
    >
      <SortableContext
        items={displayedFields.map((field) => ({ id: String(field) }))}
        strategy={horizontalListSortingStrategy}
      >
        <Wrapper>
          <FirstColumn>
            <ButtonSelectFields />
          </FirstColumn>

          {displayedFields.map((indexName): ReactElement => {
            const { label, isSortable, sortField } = definitionFields.find((field) => field.indexName === indexName)
            const sortOrder = key === sortField ? order : null

            return (
              <SortableItem
                id={String(indexName)}
                isSortable={isSortable}
                sortField={String(sortField)}
                order={sortOrder}
                key={'display' + String(indexName)}
              >
                <HeaderField isSortable={isSortable}>
                  {label}
                  {isSortable ? <SortableButton order={sortOrder} /> : null}
                </HeaderField>
              </SortableItem>
            )
          })}
        </Wrapper>
      </SortableContext>
    </DndContext>
  )
}

const SortableItem = ({
  children,
  id,
  isSortable,
  order,
  sortField,
}: {
  children: ReactElement
  id: string
  isSortable: boolean
  order: Order
  sortField: string
}): ReactElement => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    data: { isSortable, order, sortField },
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={{ display: 'flex', flex: 1, width: '100%', ...style }} {...attributes} {...listeners}>
      {children}
    </div>
  )
}

const handleDragEnd = ({ event, displayedFields, setDisplayedFields, onSort }): void => {
  const { active, over } = event

  if (active.id !== over.id) {
    const oldIndex = displayedFields.indexOf(active.id)
    const newIndex = displayedFields.indexOf(over.id)

    const copyList = [...displayedFields]
    copyList.splice(oldIndex, 1)
    copyList.splice(newIndex, 0, displayedFields[oldIndex])

    setDisplayedFields(copyList)
  } else {
    const {
      data: {
        current: { isSortable, order, sortField },
      },
    } = active
    if (isSortable) {
      onSort(String(sortField), order ? (order === Order.ASC ? Order.DESC : Order.ASC) : Order.ASC)
    }
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  flex-direction: row;
  justify-content: center;
  background-color: ${Theme.colors.blueArtizen};
  color: white;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 5px;
`
// box-shadow: lightgray 0px 0px 10px;

const HeaderField = styled.div<{ isSortable?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  font-weight: 500;
  ${Theme.font.size.normal};
  letter-spacing: 0.25px;
  font-family: ${Theme.font.family};
  cursor: ${(props) => (props.isSortable ? 'pointer' : 'default')};
  text-align: center;
`
const FirstColumn = styled(HeaderField)`
  width: 40px;
  flex: initial;
`
