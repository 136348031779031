import { Facture } from '@Arti-zen/package-backoffice'

export const calcPromoHTcts = (fact: Facture) => {
  const detail = fact.detail

  switch (detail?.promo?.type || detail.promo) {
    case 'ABSOLUTE':
      return detail.promo.amount * 100
    case 'RELATIVE':
      return (detail.mainSubscription + detail.subAccounts + detail.options) * (detail.promo.amount / 100)
    default:
      return null
  }
}

export type TFactureFulFilled = Facture & {
  mainHtCts: number
  subHtCts: number
  promoHtCts: number
  optionsHtCts: number
  variableHtCts: number
  totalHtCts: number
}

export const factFulfilled = (fact: Facture): TFactureFulFilled => {
  const factFulfilled = {
    ...fact,
    mainHtCts: fact?.detail?.mainSubscription || 0,
    subHtCts: fact?.detail?.subAccounts || 0,
    promoHtCts: calcPromoHTcts(fact),
    optionsHtCts: fact?.detail?.options,
  }

  const totalHorsPaiement =
    factFulfilled.mainHtCts + factFulfilled.subHtCts + factFulfilled.optionsHtCts - factFulfilled.promoHtCts

  return {
    ...factFulfilled,
    variableHtCts: !fact.amount ? 0 : Number(factFulfilled.amount) * 100 - totalHorsPaiement,
    totalHtCts: totalHorsPaiement,
  }
}
