import { Client, Intervention, QuotationComptaStatus } from '@Arti-zen/package-backoffice'

export enum QuotationStatusFilter {
  'all' = 'all',
  'draft' = 'draft',
  'accepted' = 'accepted',
  'waiting' = 'waiting',
  'cancelled' = 'cancelled',
}

export const statusByMenu: { [key in QuotationStatusFilter]: QuotationComptaStatus[] } = {
  all: [
    QuotationComptaStatus.Accepted,
    QuotationComptaStatus.Cancelled,
    QuotationComptaStatus.Finalized,
    QuotationComptaStatus.Waiting,
  ],
  draft: [QuotationComptaStatus.Draft],
  accepted: [QuotationComptaStatus.Accepted],
  waiting: [QuotationComptaStatus.Waiting],
  cancelled: [QuotationComptaStatus.Cancelled],
}
export interface IQuotationList {
  search?: string
  filterBase?: {
    clientId?: Client['id']
    status?: QuotationComptaStatus[]
    interventionIds?: Intervention['id'][]
    startDate?: Date
    endDate?: Date
  }
}
