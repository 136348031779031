import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { Invoice, InvoiceClass } from '@Arti-zen/package-backoffice'
import { IInvoiceState } from './store.invoice.interfaces'

const get = {
  action: createAsyncThunk('invoice/get', async (id: Invoice['id']) => {
    return ApiArtizenBO.invoices.getOne(id)
  }),
  reducer: (builder: ActionReducerMapBuilder<IInvoiceState>) => {
    builder
      .addCase(get.action.pending, (state) => {
        state.invoice = null
        state.isLoading = true
      })
      .addCase(get.action.fulfilled, (state, action) => {
        state.invoice = new InvoiceClass(action.payload)
        state.isLoading = false
      })
      .addCase(get.action.rejected, (state, _action) => {
        state.invoice = null
        state.isLoading = false
      })
  },
}

const generatePdf = {
  action: createAsyncThunk('invoice/generate', async (id: Invoice['id']) => {
    return ApiArtizenBO.invoices.pdfGenerate(id)
  }),
  reducer: (builder: ActionReducerMapBuilder<IInvoiceState>) => {
    builder
      .addCase(generatePdf.action.pending, (state) => {
        state.invoice = null
        state.isLoading = true
      })
      .addCase(generatePdf.action.fulfilled, (state, action) => {
        state.invoice = new InvoiceClass(action.payload)
        state.isLoading = false
      })
      .addCase(generatePdf.action.rejected, (state, _action) => {
        state.invoice = null
        state.isLoading = false
      })
  },
}

export const asyncActions = {
  get: get.action,
  generatePdf: generatePdf.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IInvoiceState>) => {
  get.reducer(builder)
  generatePdf.reducer(builder)
}
