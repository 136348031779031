import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { IArtisanListState } from './store.artisanList.interfaces'

const getAll = {
  action: createAsyncThunk('artisanList/getAll', async () => ApiArtizenBO.artizenUsers.get()),
  reducer: (builder: ActionReducerMapBuilder<IArtisanListState>) => {
    builder
      .addCase(getAll.action.fulfilled, (state, action) => {
        state.artisanListBase = action.payload
      })
      .addCase(getAll.action.rejected, (state, _action) => {
        state.artisanListBase = []
      })
  },
}

const getListBySearch = {
  action: createAsyncThunk('artisanList/getListBySearch', async (searchText?: string) =>
    ApiArtizenBO.artizenUsers.getBySearch({ ...(searchText ? { searchText } : {}) })
  ),
  reducer: (builder: ActionReducerMapBuilder<IArtisanListState>) => {
    builder
      .addCase(getListBySearch.action.fulfilled, (state, action) => {
        state.artisanListDisplayed = action.payload
      })
      .addCase(getListBySearch.action.rejected, (state, _action) => {
        state.artisanListDisplayed = []
      })
  },
}

export const asyncActions = {
  getAll: getAll.action,
  getListBySearch: getListBySearch.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IArtisanListState>) => {
  getAll.reducer(builder)
  getListBySearch.reducer(builder)
}
