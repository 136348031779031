import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { NavLink } from 'react-router-dom'
import { TFactureMissing } from '../../store/store.facturation.interfaces'
import { pages } from 'navigation/pages'
import { Button, Dialog, DialogContent, Select } from '@mui/material'
import { Theme } from 'theme/theme'
import { useState } from 'react'

export const definitionFields = (
  openModal: (clientId: number, year: number, numero: number) => void
): ITableInfinite<TFactureMissing>['definitionFields'] => {
  return [
    {
      indexName: 'user',
      label: 'Client',
      format: ({ user }) => (
        <NavLink to={pages.connected.artisan.facturation.navigate(user.id)} target="_blank">
          {user.id} {user.email}
        </NavLink>
      ),
    },
    {
      indexName: 'subscriptionDate',
      label: 'Date inscription',
      format: ({ subscriptionDate }) => subscriptionDate?.toFormat('dd/MM/yyyy'),
    },
    {
      indexName: 'resilisationDate',
      label: 'Date résiliation',
      format: ({ resilisationDate }) => resilisationDate?.toFormat('dd/MM/yyyy'),
    },
    {
      indexName: 'diff',
      label: 'Ecart',
    },
    {
      indexName: 'nbFactureTheo',
      label: 'Nb Fact Theo',
    },
    {
      indexName: 'nbFactureReal',
      label: 'Nb Fact Réel',
    },
    {
      indexName: 'factureTheoList',
      label: 'List Theo',
      format: ({ factureTheoList, factRealList }) => {
        const missingFactures = factureTheoList.filter((internRef) => !factRealList.includes(internRef))
        return <ModalMissingList missingFactures={missingFactures} openModal={openModal} />
      },
    },
  ]
}

const ModalMissingList = ({
  missingFactures,
  openModal,
}: {
  missingFactures: string[]
  openModal: (clientId: number, year: number, numero: number) => void
}) => {
  const [open, setOpen] = useState(false)
  const onOpenModalFact = (clientId: number, year: number, numero: number) => {
    setOpen(false)
    openModal(clientId, year, numero)
  }

  if (missingFactures.length > 0) {
    return (
      <div>
        <Button
          onClick={() => setOpen(true)}
          size="small"
          variant="text"
          style={{ backgroundColor: Theme.colors.blueArtizen, color: 'white' }}
        >
          View missing factures
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <div style={{ height: '100', overflow: 'auto' }}>
              {missingFactures.map((internalRef) => ButtonFactureMissingCreate(internalRef, onOpenModalFact))}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  } else {
    return <></>
  }
}

const ButtonFactureMissingCreate = (
  internalRef: string,
  openModal: (clientId: number, year: number, numero: number) => void
) => {
  const [clientId, year, numero] = internalRef.split('_')

  return (
    <div key={internalRef}>
      <Button onClick={() => openModal(+clientId, +year, +numero)} size="small" variant="text">
        {internalRef}
      </Button>
    </div>
  )
}
