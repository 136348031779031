import { CloseOutlined, CloseRounded, SearchRounded } from '@mui/icons-material'
import { ChangeEvent, useRef } from 'react'
import styled from 'styled-components'
import { Theme } from 'theme/theme'

interface ISearchInput {
  placeholder: string
  onChange: (value: string) => void
  displayCloseCross?: boolean
  displaySearch?: boolean
  wrapperStyle?: React.CSSProperties
}

export default function SearchInputSync({
  placeholder,
  onChange,
  displayCloseCross,
  displaySearch,
  wrapperStyle = {},
}: Readonly<ISearchInput>) {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Wrapper style={wrapperStyle}>
      <Input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      />
      {displayCloseCross ? (
        <CloseCrossWrapper
          onClick={() => {
            if (inputRef.current?.value) inputRef.current.value = ''
            onChange(null)
          }}
        >
          {inputRef?.current?.value ? <CloseRounded /> : null}
        </CloseCrossWrapper>
      ) : null}

      <div
        style={{
          display: 'flex',
          backgroundColor: Theme.colors.blueArtizen,
          color: 'white',
          height: '100%',
          width: 58,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <SearchRounded />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: solid 1px;
  border-radius: 4px;
  background-color: white;
  border-color: ${(props) => props.theme.fourthColor};
  color: ${(props) => props.theme.secondaryColor};
  width: 100% !important;
  height: 40px !important;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
`

const Input = styled.input`
  flex: 1;
  padding-left: 16px;
  width: 100 %;
  outline: none;
  border: none;
  position: relative;
  box-sizing: border - box;
  font-weight: 300;
  :placeholder: {
    font-size: 5px;
  }
`

const CloseCrossWrapper = styled.div`
  margin-right: 10px;
  color: ${Theme.colors.blueArtizen};
`
