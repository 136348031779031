import { UserArtizen } from '@Arti-zen/package-backoffice'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { artisanActions } from '../../store/store.artisan'
import ArtisanSubscriptionForm from './ArtisanSubscription.form'
import ArtisanCodePromos from './ArtisanCodePromos'
import ArtisanSubAccounts from './ArtisanSubAccounts'
import ArtisanPaymentOption from './ArtisanPaymentOption'

export default function ArtisanSubscription(): ReactElement {
  const dispatch = useAppDispatch()
  const { artisan, isLoading } = useAppSelector((state) => state.artisan)

  const onSubmit: SubmitHandler<UserArtizen> = (data) => {
    dispatch(
      artisanActions.patch({
        id: artisan.id,
        changes: data,
      })
    )
  }

  return !isLoading && artisan ? (
    <Wrapper>
      <ArtisanSubscriptionForm artisan={artisan} onSubmit={onSubmit} />

      <ArtisanPaymentOption />

      <ArtisanCodePromos />

      <ArtisanSubAccounts />
    </Wrapper>
  ) : null
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  gap: 50px;
`
