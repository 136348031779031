import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { dashboardActions } from '../../store/store.dashboard'
import { DatePicker } from '@mui/x-date-pickers'
import DashboardProductOrders from './DashboardPartnerFigures.productOrder'
import { componentByMonth } from '../DashboardFigures/dashboardFigures.helpers'
import ArtisanLinkedList from './ArtisanLinkedList'
import { Button, Icon } from '@mui/material'
import { SwapHoriz } from '@mui/icons-material'

export default function DashboardPartnerFigures(): ReactElement {
  const dispatch = useAppDispatch()
  const { dateStart, dateEnd } = useAppSelector((state) => state.dashboard)
  const [infoDisplayed, setInfoDisplayed] = useState<'stats' | 'list'>('list')

  useEffect(() => {
    dispatch(dashboardActions.getPartnerStats())
  }, [dateStart, dateEnd])

  return (
    <Wrapper>
      <Button
        variant="outlined"
        startIcon={<SwapHoriz />}
        onClick={() => setInfoDisplayed(infoDisplayed === 'stats' ? 'list' : 'stats')}
        style={{ marginBottom: 20 }}
      >
        {infoDisplayed === 'stats' ? 'Liste' : 'Statistiques'}
      </Button>

      <div
        style={{
          display: infoDisplayed === 'stats' ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: 50, marginTop: 50 }}>
          <DatePicker
            label="Date de début"
            value={dateStart}
            onChange={(e) => dispatch(dashboardActions.setDateStart(e))}
            timezone="Europe/Paris"
          />

          <DatePicker label="Date de fin" value={dateEnd} onChange={(e) => dispatch(dashboardActions.setDateEnd(e))} />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 50 }}>
          <table style={{ border: 'solid 1px', margin: 20 }}>
            <tr>
              <th></th>
              {componentByMonth({
                dateStart,
                dateEnd,
                component: (year, month) => <th style={{ padding: 10, textAlign: 'center' }}>{year + '/' + month}</th>,
              })}
            </tr>

            <DashboardProductOrders />
          </table>
        </div>
      </div>

      <ArtisanLinkedList show={infoDisplayed === 'list'} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`
