import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Facture, TPayment } from '@Arti-zen/package-backoffice'
import { NavLink, useParams } from 'react-router-dom'
import { Format } from 'utils/format'
import { Button } from '@mui/material'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { statusPaymentProgress } from '../store/store.facturation.helpers'
import { pages } from 'navigation/pages'
import { Theme } from 'theme/theme'

export default function Payment(): ReactElement {
  const [payment, setPayment] = useState<TPayment>(null)
  const [factures, setFactures] = useState<Facture[]>([])

  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    let isMount = true
    if (id) {
      ApiArtizenBO.payment
        .getOne(id)
        .then((payment) => isMount && setPayment(payment))
        .catch(console.error)
    }

    return () => {
      isMount = false
    }
  }, [id])

  useEffect(() => {
    let isMount = true

    if (payment?.saasClient?.artizenId) {
      ApiArtizenBO.facturation
        .getAll({ clientId: payment.saasClient.artizenId })
        .then((factures) => isMount && setFactures(factures ?? []))
    } else {
      setFactures([])
    }

    return () => {
      isMount = false
    }
  }, [payment])

  const linkFacture = async (factureId: Facture['id']) => {
    const payment = await ApiArtizenBO.payment.patchOne(id, { factureId })
    setPayment(payment)
  }

  const unLinkFacture = async () => {
    const payment = await ApiArtizenBO.payment.patchOne(id, { factureId: null })
    setPayment(payment)
  }

  if (!payment)
    return (
      <Wrapper>
        <WrapperContent>No payment</WrapperContent>
      </Wrapper>
    )

  return (
    <Wrapper>
      <WrapperContent>
        <Title>Paiement {payment.GCLid}</Title>
        <div>
          Gcl Id :{' '}
          <a href={'https://manage.gocardless.com/payments/' + payment.GCLid} target="_blank">
            {payment.GCLid}
          </a>
        </div>
        <div>artizen client id : {payment.saasClient?.artizenId}</div>
        <div>Montant TTC : {Format.currency(payment.amountInCts / 100, 2)}</div>
        <div>Remboursement TTC : {Format.currency(-payment.amountRefunded / 100, 2)}</div>
        <div>Date prélèvement : {Format.dateFr(payment.chargeDate)}</div>
        <div>Status : {statusPaymentProgress([payment])}</div>

        <div style={{ marginTop: 50 }}>Facture liée : </div>
        {factures
          .filter((fact) => fact.payments.map((payment) => payment.id).includes(payment.id))
          .map((fact) => (
            <FactureDisplay
              key={`facture-${fact.id}`}
              facture={fact}
              linkAction={linkFacture}
              unLinkAction={unLinkFacture}
            />
          ))}

        <div style={{ marginTop: 50 }}>Factures de ce client : </div>
        {factures
          .filter((fact) => !fact.payments.map((payment) => payment.id).includes(payment.id))
          .map((fact) => (
            <FactureDisplay
              key={`facture-${fact.id}`}
              facture={fact}
              linkAction={linkFacture}
              unLinkAction={unLinkFacture}
            />
          ))}
      </WrapperContent>
    </Wrapper>
  )
}

const FactureDisplay = (params: {
  facture: Facture
  linkAction: (id: Facture['id']) => void
  unLinkAction: () => void
}) => {
  const { facture, linkAction, unLinkAction } = params

  const LinkButton = () => (
    <Button variant="contained" onClick={() => linkAction(facture.id)}>
      Lier
    </Button>
  )
  const UnLinkButton = () => (
    <Button variant="outlined" onClick={() => unLinkAction()}>
      Délier
    </Button>
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 50, textAlign: 'center', alignItems: 'center' }}>
      <NavLink to={pages.connected.factureList.facture.navigate(facture.id)}>{facture.internalRef}</NavLink>
      <div style={{ flex: 1 }}>{Format.currency(+facture.amountTTC, 2)}</div>
      <div style={{ flex: 1 }}>
        {facture.payments.length === 1 ? <UnLinkButton /> : facture.payments.length === 0 ? <LinkButton /> : 'multi'}
      </div>
      <div style={{ flex: 1 }}>{Format.dateFr(facture.createdAt)}</div>
    </div>
  )
}

const Title = styled.div`
  display: flex;
  justify-content: center;
  ${Theme.font.size.xlarge};
  font-weight: 700;
`

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`
