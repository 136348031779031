import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useMemo, useState } from 'react'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Order } from 'components/table/infinite/InfiniteTable.interfaces'
import { factureListActions } from '../../store/store.facturation'
import { DatePicker } from '@mui/x-date-pickers'
import { Button, TextField } from '@mui/material'
import { TFactureMissing } from '../../store/store.facturation.interfaces'
import CsvDownloadButton from 'react-json-to-csv'
import { definitionFields } from './FactureMissingList.definitionFields'
import { LeftBar, Wrapper, WrapperContent } from './FactureMissingList.styled'
import { useDebounce } from 'hooks/useDebounce'
import FactureMissingCreateModal from './FactureMissingCreate.modal'

export default function FactureMissingList(): ReactElement {
  const dispatch = useAppDispatch()
  const { factureMissingList, dateStart, dateEnd, isRefreshing } = useAppSelector((state) => state.facturation)
  const [search, setSearch] = useState<string>()
  const textSearched = useDebounce(search, 500)
  const [modal, setModal] = useState<{ clientId?: number; year?: number; numero?: number; isOpen?: boolean }>({
    isOpen: false,
  })

  const openModal = (clientId: number, year: number, numero: number) =>
    setModal({ clientId, year, numero, isOpen: true })
  const fields = definitionFields(openModal)
  console.log(modal)

  const displayedFactureMissingList = useMemo(() => {
    if (!textSearched) return factureMissingList

    return factureMissingList.filter((fact) => {
      const tests = [fact.user?.email.includes(textSearched), String(fact.user?.id).includes(textSearched)]

      return tests.some((test) => test === true)
    })
  }, [textSearched, factureMissingList])

  const [displayedFields, setDisplayedFields] = useState<(keyof TFactureMissing)[]>(fields.map((def) => def.indexName))
  const [activeSort, setActiveSort] = useState<{ key: keyof TFactureMissing; order: Order }[]>([
    { key: 'subscriptionDate', order: Order.DESC },
  ])

  const onSort = (key: keyof TFactureMissing, order: Order) => {
    setActiveSort([{ key, order }])
  }

  return (
    <Wrapper>
      <LeftBar>
        <DatePicker
          label="Date de début"
          value={dateStart}
          onChange={(e) => dispatch(factureListActions.setDateStart(e))}
          timezone="Europe/Paris"
          format="dd/MM/yyyy"
        />

        <DatePicker
          label="Date de fin"
          value={dateEnd}
          onChange={(e) => dispatch(factureListActions.setDateEnd(e))}
          timezone="Europe/Paris"
          format="dd/MM/yyyy"
        />

        <CsvDownloadButton
          data={factureMissingList}
          filename={`Factures manquantes du ${dateStart.toFormat('yyyy/MM/dd')} au ${dateEnd.toFormat('yyyy/MM/dd')}`}
          style={{ margin: 5 }}
        >
          Télécharger
        </CsvDownloadButton>
      </LeftBar>

      <WrapperContent>
        <div
          style={{
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Rechercher une facture (artisanId, email)"
            style={{ display: 'flex', width: '50%' }}
          />
          <Button onClick={() => dispatch(factureListActions.getList())} variant="contained" disabled={isRefreshing}>
            {isRefreshing ? '...' : 'Refresh'}
          </Button>
        </div>
        <TableInfinite<TFactureMissing>
          displayedFields={displayedFields}
          setDisplayedFields={setDisplayedFields}
          definitionFields={fields}
          onSort={onSort}
          activeSorts={activeSort}
          data={displayedFactureMissingList}
          nextPage={() => {}}
          isQueriedAllCompleted={true}
          firstColumn={{
            format: () => <></>,
          }}
        />
      </WrapperContent>
      <FactureMissingCreateModal
        clientId={modal.clientId}
        year={modal.year}
        numero={modal.numero}
        open={modal.isOpen}
        onClose={() => setModal({ ...modal, isOpen: false })}
      />
    </Wrapper>
  )
}
