import { TCodePromoUser } from '@Arti-zen/package-backoffice'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { Format } from 'utils/format'
import { DateTime } from 'luxon'
import { Theme } from 'theme/theme'
import { Button, Dialog, DialogContent } from '@mui/material'
import ArtisanCodePromoForm from './ArtisanCodePromos.form'
import { artisanActions } from 'modules/artisan/store/store.artisan'
import { ApiArtizenBO } from 'utils/artizenConnector'

export default function ArtisanCodePromos(): ReactElement {
  const dispatch = useAppDispatch()
  const { artisan, isLoading } = useAppSelector((state) => state.artisan)

  const [showCodePromo, setShowCodePromo] = useState(false)

  const isParent = !artisan.parentId

  return !isLoading && artisan && isParent ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      <Title>Promos</Title>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', fontWeight: 600 }}>
        <Item>Id</Item>
        <Item>Code</Item>
        <Item>Promo</Item>
        <Item>Date début</Item>
        <Item>Date de fin</Item>
        <Item></Item>
      </div>
      {artisan.codePromoList
        .filter((codePromoUser) => codePromoUser.promoCodeId > 0 && codePromoUser.promoCode)
        .reverse()
        .map((codePromo) => (
          <CodePromo key={`CodePromo-${codePromo.id}`} codePromo={codePromo} />
        ))}

      <Button
        onClick={() => setShowCodePromo(true)}
        style={{
          display: 'flex',
          alignSelf: 'center',
          backgroundColor: Theme.colors.blueArtizen,
          color: 'white',
          width: 200,
        }}
      >
        Ajouter Promo
      </Button>

      <Dialog open={showCodePromo} onClose={() => setShowCodePromo(false)}>
        <DialogContent>
          <ArtisanCodePromoForm
            artisanId={artisan.id}
            onSubmit={async () => {
              await dispatch(artisanActions.get(artisan.id)).unwrap()
              setShowCodePromo(false)
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  ) : null
}

const CodePromo = ({ codePromo }: { codePromo: TCodePromoUser }): ReactElement => {
  const [currentCP, setCurrentCP] = useState(codePromo)

  const { promoCode, promoStart, promoEnd } = currentCP

  const stopCodePromo = async () => {
    const newCodePromo = await ApiArtizenBO.codePromo.patchPromoCodeUser(codePromo.id, {
      startDate: codePromo.promoStart,
      endDate: DateTime.now().toUTC().toISO(),
    })
    setCurrentCP(newCodePromo)
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
      <Item>{promoCode.id}</Item>
      <Item>{promoCode.code}</Item>
      <Item>
        {promoCode.absoluteDiscount ? Format.currency(promoCode.absoluteDiscount) : promoCode.relativeDiscount + '%'}
      </Item>
      <Item>{DateTime.fromISO(promoStart).toFormat('dd/MM/yyyy')}</Item>
      <Item>{DateTime.fromISO(promoEnd).toFormat('dd/MM/yyyy')}</Item>
      <Item>
        {DateTime.fromJSDate(new Date(promoEnd)).diffNow().as('seconds') > 0 && (
          <Button color="warning" variant="outlined" onClick={stopCodePromo}>
            Stop au {DateTime.now().toFormat('dd/MM/yyyy')}
          </Button>
        )}
      </Item>
    </div>
  )
}

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  ${Theme.font.size.xlarge};
  font-weight: 700;
  border-bottom: 1px solid;
`

const Item = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
