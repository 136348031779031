import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.quotation.thunks'
import { IQuotationState } from './store.quotation.interfaces'

const initialState: IQuotationState = {
  quotation: null,
}

const quotationSlice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    init() {
      return initialState
    },
  },
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const quotationActions = { ...quotationSlice.actions, ...thunks.asyncActions }
export default quotationSlice.reducer
