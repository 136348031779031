import moment from 'moment'

const currency = (num: string | number, decimal = 0) =>
  Number(num).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: decimal })

const dateFr = (date: string) => moment(date).format('DD/MM/YYYY')

export const Format = {
  currency,
  dateFr,
}
