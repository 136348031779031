import { ReactElement, useState } from 'react'
import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import InputText from 'components/forms/InputText'

interface IFactureManualPaymentModal {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  submitPaymentManual: (comment: string) => void
}

export default function FactureManualPaymentModal(params: Readonly<IFactureManualPaymentModal>): ReactElement {
  const { isModalOpen, setIsModalOpen, submitPaymentManual } = params
  const [comment, setComment] = useState('')

  const submit = () => submitPaymentManual(comment)

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <TextField id="outlined-multiline-static" label="Commentaire" onChange={(e) => setComment(e.target.value)} />
          <Button variant="contained" onClick={submit}>
            Valider le paiement manuel
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
