import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { EPaymentProgressStatus, IFacturationListState, PaymentProgressTranslate } from './store.facturation.interfaces'
import { RootState } from 'store/store'
import { Facture } from '@Arti-zen/package-backoffice'
import { applyFilters, factTheo, factureExtended, nbFactTheoCalc } from './store.facturation.helpers'
import { DateTime } from 'luxon'

const getList = {
  action: createAsyncThunk('factureList/getList', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { dateStart, dateEnd } = state.facturation
    const { artisanListBase } = state.artisanList

    const factList = await ApiArtizenBO.facturation.getAll({
      dateStart: dateStart?.toString(),
      dateEnd: dateEnd?.toString(),
    })

    return { factList, artisanListBase }
  }),
  reducer: (builder: ActionReducerMapBuilder<IFacturationListState>) => {
    builder
      .addCase(getList.action.pending, (state, _action) => {
        state.isRefreshing = true
      })
      .addCase(getList.action.fulfilled, (state, action) => {
        const { artisanListBase, factList } = action.payload
        const factureExtendedList = factList.map((fact) => factureExtended(fact, artisanListBase))
        state.factures = factureExtendedList
        state.displayedFactures = applyFilters(state)
        state.isRefreshing = false
      })
      .addCase(getList.action.rejected, (state, _action) => {
        state.factures = []
        state.isRefreshing = false
      })
  },
}

const updateFacturePayment = {
  action: createAsyncThunk('factureList/updateFacturePayment', async (factureId: Facture['id'], thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { artisanListBase } = state.artisanList

    const factureUpdated = await ApiArtizenBO.facturation.updatePayment(factureId)

    return { factureUpdated, artisanListBase }
  }),
  reducer: (builder: ActionReducerMapBuilder<IFacturationListState>) => {
    builder
      .addCase(updateFacturePayment.action.pending, (state, action) => {
        state.displayedFactures = state.displayedFactures.map((fact) => {
          if (fact.id === action.meta.arg) {
            return {
              ...fact,
              paymentProgress: EPaymentProgressStatus.refreshing,
            }
          } else {
            return fact
          }
        })
      })

      .addCase(updateFacturePayment.action.fulfilled, (state, action) => {
        const { factureUpdated, artisanListBase } = action.payload

        state.factures = state.factures.map((fact) => {
          if (fact.id === action.meta.arg) {
            return factureExtended(factureUpdated, artisanListBase)
          } else {
            return fact
          }
        })

        state.displayedFactures = state.displayedFactures.map((fact) => {
          if (fact.id === action.meta.arg) {
            return factureExtended(factureUpdated, artisanListBase)
          } else {
            return fact
          }
        })
      })
      .addCase(updateFacturePayment.action.rejected, (state, action) => {
        state.displayedFactures = state.displayedFactures.map((fact) => {
          if (fact.id === action.meta.arg) {
            return {
              ...fact,
              paymentProgress: EPaymentProgressStatus.refreshError,
            }
          } else {
            return fact
          }
        })
      })
  },
}

const getPaymentOrphanList = {
  action: createAsyncThunk('factureList/getPaymentOrphanList', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { artisanListBase } = state.artisanList

    const paymentOrphanList = await ApiArtizenBO.payment.getOrphanList()
    return { paymentOrphanList, artisanListBase }
  }),
  reducer: (builder: ActionReducerMapBuilder<IFacturationListState>) => {
    builder
      .addCase(getPaymentOrphanList.action.pending, (state, _action) => {
        state.isRefreshing = true
      })
      .addCase(getPaymentOrphanList.action.fulfilled, (state, action) => {
        const { paymentOrphanList, artisanListBase } = action.payload

        state.paymentOrphanList = paymentOrphanList
          .map((paymentOrphan) => ({
            ...paymentOrphan,
            artisan: artisanListBase.find((user) => user.id === +paymentOrphan?.saasClient?.artizenId),
            chargeDateTime: DateTime.fromISO(paymentOrphan.chargeDate),
            paymentProgress: PaymentProgressTranslate[paymentOrphan.status],
          }))
          .sort((a, b) => (b.chargeDateTime.diff(a.chargeDateTime, 'milliseconds').milliseconds > 0 ? 1 : -1))
        state.isRefreshing = false
      })
      .addCase(getPaymentOrphanList.action.rejected, (state, _action) => {
        state.paymentOrphanList = []
        state.isRefreshing = false
      })
  },
}

const getFactureMissingList = {
  action: createAsyncThunk('factureList/getFactureMissingList', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { dateStart, dateEnd } = state.facturation
    const { artisanListBase } = state.artisanList

    const factures = await ApiArtizenBO.facturation.getAll({
      dateStart: dateStart?.plus({ day: 1 }).toString(),
      dateEnd: dateEnd?.toString(),
    })

    return { factures, artisanListBase }
  }),
  reducer: (builder: ActionReducerMapBuilder<IFacturationListState>) => {
    builder
      .addCase(getFactureMissingList.action.pending, (state, _action) => {
        state.isRefreshing = true
      })
      .addCase(getFactureMissingList.action.fulfilled, (state, action) => {
        const { factures, artisanListBase } = action.payload
        const { dateStart, dateEnd } = state

        state.factureMissingList = artisanListBase
          .filter((user) => !user.parentId && !user.demoAccount && user.subscriptionDate && user.subscriptionDate)
          .map((user) => {
            const subscriptionDate = user.subscriptionDate ? DateTime.fromISO(user.subscriptionDate) : null
            const resilisationDate = user.resiliationDatetime ? DateTime.fromISO(user.resiliationDatetime) : null

            const factureTheoList = factTheo({
              clientId: user.id,
              dateEnd: dateEnd.minus({ day: 1 }).endOf('day'),
              dateStart: dateStart.startOf('day'),
              subscriptionDate: subscriptionDate?.startOf('day'),
              resilisationDate: resilisationDate?.endOf('day'),
            })

            const nbFactureTheo = factureTheoList.length

            const factRealList = factures.filter((fact) => fact.clientId === user.id).map((fact) => fact.internalRef)

            const nbFactureReal = factures.filter((fact) => fact.clientId === user.id).length
            const diff = nbFactureReal - nbFactureTheo

            return {
              id: user.id,
              user,
              subscriptionDate,
              resilisationDate,
              nbFactureReal,
              nbFactureTheo,
              diff,
              factureTheoList,
              factRealList,
            }
          })
          .filter((userStat) => userStat.diff !== 0)
          .sort((a, b) => a.diff - b.diff)

        state.isRefreshing = false
      })
      .addCase(getFactureMissingList.action.rejected, (state, _action) => {
        state.factureMissingList = []
        state.isRefreshing = false
      })
  },
}

export const asyncActions = {
  getList: getList.action,
  updateFacturePayment: updateFacturePayment.action,
  getPaymentOrphanList: getPaymentOrphanList.action,
  getFactureMissingList: getFactureMissingList.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IFacturationListState>) => {
  getList.reducer(builder)
  updateFacturePayment.reducer(builder)
  getPaymentOrphanList.reducer(builder)
  getFactureMissingList.reducer(builder)
}
