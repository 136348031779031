import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.auth.thunks'
import { IAuthState } from './store.auth.interfaces'

const initialState: IAuthState = {
  user: undefined,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout() {
      return initialState
    },
  },
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const authActions = { ...authSlice.actions, ...thunks.asyncActions }
export default authSlice.reducer
