import { InterventionClass, InvoiceClass, QuotationClass, UserArtizen } from '@Arti-zen/package-backoffice'
import { DateTime } from 'luxon'
import { FactureExtended } from 'modules/facturation/store/store.facturation.interfaces'
import { InferType, boolean, date, number, object, string } from 'yup'

export interface IArtisanState {
  dashboard: {
    dateStart: DateTime
    dateEnd: DateTime
    interventions: InterventionClass['meilisearchDoc'][]
    quotations: QuotationClass['meilisearchDoc'][]
    invoices: InvoiceClass['meilisearchDoc'][]
  }
  artisan?: UserArtizen
  children?: UserArtizen[]
  factureList: FactureExtended[]
  isLoading?: boolean
}

export const artisanSchema = object({
  id: number().notRequired(),
  email: string().email().required(),
  accountantEmail: string().email().notRequired(),
  firstName: string().notRequired(),
  lastName: string().notRequired(),
  phone: string().notRequired(),
  companyName: string().notRequired(),
  companyType: string().notRequired(),
  companyLogoUri: string().notRequired(),
  companyLogoPutUrl: string().notRequired(),
  vat: string().notRequired(),
  shareCapital: string().notRequired(),
  rcs: string().notRequired(),
  address: string().notRequired(),
  postalCode: string().notRequired(),
  city: string().notRequired(),
  careers: string().notRequired(),
  siren: string().notRequired(),
  creditor_id: string().notRequired(),
  mandate_id: string().notRequired(),
  customer_id: string().notRequired(),
  customer_bank_account_id: string().notRequired(),
  previous_creditor_id: string().notRequired(),
  previous_mandate_id: string().notRequired(),
  previous_customer_id: string().notRequired(),
  previous_customer_bank_account_id: string().notRequired(),
  subscription_id: string().notRequired(),
  previous_subscription_id: string().notRequired(),
  commited_month: number().notRequired(),
  subscriptionPrice: number().notRequired(),
  validCGU: boolean(),
  isFromWeb: boolean(),
  isDisactivated: boolean().notRequired(),
  validCGV: boolean().notRequired(),
  isSelfEmployed: boolean().notRequired(),
  iban: string().notRequired(),
  bic: string().notRequired(),
  password: string().notRequired(),
  passwordResetCode: string().notRequired(),
  passwordResetExpiration: string().notRequired(),
  emailValidationCode: string().notRequired(),
  emailValidationExpiration: string().notRequired(),
  version: number().notRequired(),
  quotationMentions: string().notRequired(),
  invoiceMentions: string().notRequired(),
  hasSeenNotif: boolean().notRequired(),
  created: date().notRequired(),
  updated: date().notRequired(),
  step: string().notRequired(),
  isSubscribed: boolean().notRequired(),
  showPrice: boolean().notRequired(),
  promoCode: string().notRequired(),
  creationDatetime: string().notRequired(),
  revenueCatId: string().notRequired(),
  isOnTrial: boolean().notRequired(),
  invoiceColor: string().notRequired(),
  templateNumber: number().notRequired(),
  numberOfQuotations: number().notRequired(),
  numberOfInvoices: number().notRequired(),
  numberOfAdvenceInvoices: number().notRequired(),
  numberOfAvoirInvoices: number().notRequired(),
  demoAccount: boolean().notRequired(),
  website: string().notRequired(),
  subscriptionDate: string().when('isSubscribed', {
    is: (val) => {
      console.log('isSubscribed', val)
      return val === true
    },
    then: (s) => s.required(),
    otherwise: (s) => s.notRequired(),
  }),
  resiliationDatetime: string().when(['isSubscribed', 'subscriptionDate'], {
    is: (isSubscribed: boolean, subscriptionDate: string) => {
      console.log('isSubscribed', isSubscribed, 'subscriptionDate', subscriptionDate)
      return !isSubscribed && subscriptionDate
    },
    then: (s) => s.required(),
    otherwise: (s) => s.notRequired(),
  }),
  source: string().notRequired(),
})

export type TArtisan = InferType<typeof artisanSchema>
