import { useAppSelector } from 'hooks/useStore'
import { ReactElement, useMemo } from 'react'
import { Format } from 'utils/format'
import { Theme } from 'theme/theme'
import { actionByMonth, actionByMonth2, componentByMonth } from './dashboardFigures.helpers'
import DownloadButton from 'components/DownloadButton'

export default function DashboardInvoicesFigures(): ReactElement {
  const { dateStart, dateEnd, invoiceStats, invoices } = useAppSelector((state) => state.dashboard)

  return (
    <>
      <td
        style={{
          fontWeight: 600,
          color: Theme.colors.blueArtizen,
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          alignItems: 'center',
        }}
      >
        Factures
        <DownloadButton data={invoices} fileName="factures" label={null} size="small" />
      </td>
      {!invoiceStats ? null : (
        <>
          <tr>
            <td style={{ textAlign: 'right' }}>CA HT</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => (
                <td style={{ padding: 10, textAlign: 'center' }}>
                  <div>{Format.currency(invoiceStats[year]?.[month]?.totalHT, 0)}</div>
                </td>
              ),
            })}
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>Nombre</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => (
                <td style={{ padding: 10, textAlign: 'center' }}>
                  <div>{invoiceStats[year]?.[month]?.count}</div>
                </td>
              ),
            })}
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>User Unique</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => (
                <td style={{ padding: 10, textAlign: 'center' }}>
                  <div>{invoiceStats[year]?.[month]?.uniqueUserList.length}</div>
                </td>
              ),
            })}
          </tr>
        </>
      )}
    </>
  )
}
