import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { IQuotationListState, QuotationListStoreErrorType } from './store.quotationList.interfaces'
import { IQuotationsGet } from '@Arti-zen/package-backoffice'
import { RootState } from 'store/store'
import { ApiArtizenBO } from 'utils/artizenConnector'

const fetch = {
  action: createAsyncThunk('quotations/get', async (filters: IQuotationsGet | 'refresh', thunkApi) => {
    const state = thunkApi.getState() as RootState
    const newFilters = filters === 'refresh' ? { ...state.quotationList.filters, page: 1 } : filters
    return ApiArtizenBO.quotations.get(newFilters)
  }),
  reducer: (builder: ActionReducerMapBuilder<IQuotationListState>) => {
    builder
      .addCase(fetch.action.pending, (state, action) => {
        state.filters = action.meta.arg === 'refresh' ? { ...state.filters, page: 1 } : action.meta.arg
        state.errorType = null
        state.isLoading = true
      })
      .addCase(fetch.action.fulfilled, (state, action) => {
        state.isQueriedAllCompleted = action.payload.length < state.filters.limit
        state.quotations = action.payload
        state.isLoading = false
      })
      .addCase(fetch.action.rejected, (state, _action) => {
        state.quotations = []
        state.isLoading = false
        state.errorType = QuotationListStoreErrorType.network
      })
  },
}

const fetchNextPage = {
  action: createAsyncThunk('quotations/getNextPage', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { filters } = state.quotationList
    return ApiArtizenBO.quotations.get({ ...filters, page: filters.page })
  }),
  reducer: (builder: ActionReducerMapBuilder<IQuotationListState>) => {
    builder
      .addCase(fetchNextPage.action.pending, (state, _action) => {
        state.errorType = null
        state.filters.page += 1
        state.isLoading = true
      })
      .addCase(fetchNextPage.action.fulfilled, (state, action) => {
        state.isQueriedAllCompleted = action.payload.length < state.filters.limit
        state.quotations = [...state.quotations, ...action.payload]
        state.isLoading = false
      })
      .addCase(fetchNextPage.action.rejected, (state, _action) => {
        state.isLoading = false
        state.errorType = QuotationListStoreErrorType.network
      })
  },
}

export const asyncActions = {
  fetch: fetch.action,
  fetchNextPage: fetchNextPage.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IQuotationListState>) => {
  fetch.reducer(builder)
  fetchNextPage.reducer(builder)
}
