enum Devices {
  xxl = 'xxl',
  xl = 'xl',
  lg = 'lg',
  md = 'md',
}

const deviceBreakpoint: { [key in Devices]: number } = {
  md: 900,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
}

const style = (propName: string, defautlValue: string | number, values: { [key in Devices]?: string | number }) => {
  const rawStyleFlexArray = [`${propName}:${defautlValue};`]

  Object.keys(Devices).forEach((key) => {
    if (values[key]) {
      rawStyleFlexArray.push(`@media (max-width: ${deviceBreakpoint[key]}px) {
      ${propName}: ${values[key]};
    };`)
    }
  })

  return rawStyleFlexArray.join('')
}

export const Theme = {
  colors: {
    blackArtizen: '#081b3c',
    blueArtizen: 'rgb(10, 48, 175)',
    blueGray: 'rgb(69, 89, 128)',
    greenArtizen: 'rgb(30, 220, 152)',
    greyLight: '#C1C1C1',
    greyLighter: 'rgb(246,246,246)',
    greyDark: 'rgb(117, 117, 117)',
  },
  font: {
    family: 'Roboto',
    size: {
      small: style('font-size', '13px', { xl: '12px' }),
      normal: style('font-size', '14px', { xl: '13px' }),
      large: style('font-size', '17px', { xl: '15px' }),
      xlarge: style('font-size', '19px', { xl: '17px' }),
    },
  },
}
