import { UserArtizen } from '@Arti-zen/package-backoffice'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import ArtisanProfileForm from './forms/ArtisanProfile.form'
import { artisanActions } from '../store/store.artisan'

export default function ArtisanProfile(): ReactElement {
  const dispatch = useAppDispatch()
  const { artisan, isLoading } = useAppSelector((state) => state.artisan)

  const onSubmit: SubmitHandler<UserArtizen> = (data) => {
    dispatch(
      artisanActions.patch({
        id: artisan.id,
        changes: data,
      })
    )
  }

  return !isLoading && artisan ? (
    <Wrapper>
      <ArtisanProfileForm artisan={artisan} onSubmit={onSubmit} />
    </Wrapper>
  ) : null
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
