import { ReactElement, useState } from 'react'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Order } from 'components/table/infinite/InfiniteTable.interfaces'
import { Facture } from '@Arti-zen/package-backoffice'
import { definitionFields } from './FactureList.definitionFields'
import { FactureExtended } from 'modules/facturation/store/store.facturation.interfaces'

type TFactureList = {
  factureList: FactureExtended[]
  updatePayment?: (paymentId: string) => void
}

export default function FactureList({ factureList, updatePayment }: Readonly<TFactureList>): ReactElement {
  const fields = definitionFields({ updatePayment })

  const [displayedFields, setDisplayedFields] = useState<(keyof FactureExtended)[]>(fields.map((def) => def.indexName))
  const [activeSort, setActiveSort] = useState<{ key: keyof Facture; order: Order }[]>([
    { key: 'createdAt', order: Order.DESC },
  ])

  const onSort = (key: keyof Facture, order: Order) => {
    setActiveSort([{ key, order }])
  }

  return (
    <TableInfinite<FactureExtended>
      displayedFields={displayedFields}
      setDisplayedFields={setDisplayedFields}
      definitionFields={fields}
      onSort={onSort}
      activeSorts={activeSort}
      data={factureList}
      nextPage={() => {}}
      isQueriedAllCompleted={true}
      firstColumn={{
        format: () => <></>,
      }}
    />
  )
}
