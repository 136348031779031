import LayoutConnected from 'layouts/LayoutConnected'
import Login from 'modules/auth/components/Login'
import Logout from 'modules/auth/components/Logout'
import { ReactElement } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { pages } from './pages'

import ArtisanListHome from 'modules/artisanList/components/ArtisanListHome'
import ArtisanRouter from 'modules/artisan/navigation/ArtisanRouter'
import QuotationRouter from 'modules/quotation/navigation/QuotationRouter'
import InvoiceRouter from 'modules/invoice/navigation/InvoiceRouter'
import DashboardRouter from 'modules/dashboard/navigation/DashboardRouter'
import FactureListRouter from 'modules/facturation/navigation/FactureListRouter'
import SupportRouter from 'modules/support/navigation/SupportRouter'

export default function RouterMain(): ReactElement {
  document.title = 'BO Artizen'
  return (
    <Router>
      <Routes>
        <Route path={pages.login} element={<Login />} />

        <Route path={pages.connected.root} element={<LayoutConnected />}>
          <Route index element={<ArtisanListHome />} />
          <Route index path={pages.connected.artisanList.root} element={<ArtisanListHome />} />
          {ArtisanRouter()}
          {QuotationRouter()}
          {InvoiceRouter()}
          {DashboardRouter()}
          {FactureListRouter()}
          {SupportRouter()}
          <Route path={pages.connected.logout} element={<Logout />} />
        </Route>
      </Routes>
    </Router>
  )
}
