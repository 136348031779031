import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { authActions } from '../store/store.auth'
import { useNavigate } from 'react-router-dom'
import { pages } from 'navigation/pages'

export default function Login(): ReactElement {
  document.title = 'Login'

  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const [password, setPassword] = useState<string>(undefined)
  const [email, setEmail] = useState<string>(undefined)

  useEffect(() => {
    if (user) navigate(pages.connected.root)
  }, [user])

  const onClick = () => {
    dispatch(authActions.login({ email, password }))
  }

  return (
    <Wrapper>
      <Input
        type="email"
        value={email}
        onChange={(val) => setEmail(val.currentTarget.value)}
        placeholder="Saisir votre email"
      />
      <Input
        type="password"
        value={password}
        onChange={(val) => setPassword(val.currentTarget.value)}
        placeholder="Saisir votre mot de passe"
      />
      <Button onClick={onClick}>Se connecter</Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const Input = styled.input``

const Button = styled.button``
