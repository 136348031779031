import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { ClientClass, IClientsGet } from '@Arti-zen/package-backoffice'
import { RootState } from 'store/store'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { ClientListStoreErrorType, IClientListState } from './store.clientList.interfaces'

const fetch = {
  action: createAsyncThunk('clients/get', async (filters: IClientsGet | 'refresh', thunkApi) => {
    const state = thunkApi.getState() as RootState
    const newFilters = filters === 'refresh' ? { ...state.clientList.filters, page: 1 } : filters
    return ApiArtizenBO.clients.get(newFilters)
  }),
  reducer: (builder: ActionReducerMapBuilder<IClientListState>) => {
    builder
      .addCase(fetch.action.pending, (state, action) => {
        state.filters = action.meta.arg === 'refresh' ? { ...state.filters, page: 1 } : action.meta.arg
        state.errorType = null
        state.isLoading = true
      })
      .addCase(fetch.action.fulfilled, (state, action) => {
        state.isQueriedAllCompleted = action.payload.length < state.filters.limit
        state.clients = action.payload
        state.isLoading = false
      })
      .addCase(fetch.action.rejected, (state, _action) => {
        state.clients = []
        state.isLoading = false
        state.errorType = ClientListStoreErrorType.network
      })
  },
}

const fetchNextPage = {
  action: createAsyncThunk('clients/getNextPage', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { filters } = state.clientList
    return ApiArtizenBO.clients.get({ ...filters, page: filters.page })
  }),
  reducer: (builder: ActionReducerMapBuilder<IClientListState>) => {
    builder
      .addCase(fetchNextPage.action.pending, (state, _action) => {
        state.errorType = null
        state.filters.page += 1
        state.isLoading = true
      })
      .addCase(fetchNextPage.action.fulfilled, (state, action) => {
        state.isQueriedAllCompleted = action.payload.length < state.filters.limit
        state.clients = [...state.clients, ...action.payload]
        state.isLoading = false
      })
      .addCase(fetchNextPage.action.rejected, (state, _action) => {
        state.isLoading = false
        state.errorType = ClientListStoreErrorType.network
      })
  },
}

const fetchAll = {
  action: createAsyncThunk('clients/getAll', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    let clients: ClientClass['meilisearchDoc'][] = []
    let page = 0
    let stop = false

    do {
      page++
      const clientsToAdd = await ApiArtizenBO.clients.get({ ...state.clientList.filters, page, limit: 20 })
      clients.push(...clientsToAdd)
      if (clientsToAdd.length < 20) stop = true
    } while (!stop)

    return clients
  }),
  reducer: (builder: ActionReducerMapBuilder<IClientListState>) => {
    builder
      .addCase(fetchAll.action.pending, (state, action) => {
        state.errorType = null
        state.isLoading = true
      })
      .addCase(fetchAll.action.fulfilled, (state, action) => {
        state.isQueriedAllCompleted = action.payload.length < 1000
        state.clients = action.payload
        state.isLoading = false
      })
      .addCase(fetchAll.action.rejected, (state, _action) => {
        state.clients = []
        state.isLoading = false
        state.errorType = ClientListStoreErrorType.network
      })
  },
}

export const asyncActions = {
  fetch: fetch.action,
  fetchNextPage: fetchNextPage.action,
  fetchAll: fetchAll.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IClientListState>) => {
  fetch.reducer(builder)
  fetchNextPage.reducer(builder)
  fetchAll.reducer(builder)
}
