import { artisanPages } from 'modules/artisan/navigation/artisanPages'
import { dashboardPages } from 'modules/dashboard/navigation/dashboardPages'
import { factureListPages } from 'modules/facturation/navigation/factureListPages'
import { invoicePages } from 'modules/invoice/navigation/invoicePages'
import { quotationPages } from 'modules/quotation/navigation/quotationPages'
import { supportPages } from 'modules/support/navigation/supportPages'

export const pages = {
  login: '/login',
  connected: {
    root: '/',
    logout: '/logout',
    artisanList: {
      root: '/artisans',
    },
    artisan: artisanPages,
    quotation: quotationPages,
    invoice: invoicePages,
    dashboard: dashboardPages,
    factureList: factureListPages,
    stats: {
      root: 'stats',
    },
    support: supportPages,
  },
}
