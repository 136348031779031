import { TextField } from '@mui/material'
import { useDebounce } from 'hooks/useDebounce'
import ClientListTable from 'modules/clientList/components/ClientListTable/ClientListTable'
import { ReactElement, useState } from 'react'
import styled from 'styled-components'

export default function ArtisanClientList(): ReactElement {
  const [search, setSearch] = useState<string>()

  const textSearched = useDebounce(search, 500)

  return (
    <Wrapper>
      <TextField
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Rechercher un client"
        style={{ display: 'flex', width: '50%' }}
      />

      <ClientListTable search={textSearched} filterBase={{}} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 50px;
`
