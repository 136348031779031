import { TCodePromo, TCodePromoPost } from '@Arti-zen/package-backoffice'
import { Button } from '@mui/material'
import { ReactElement, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import InputTextControl from 'components/forms/InputText.control'
import { ApiArtizenBO } from 'utils/artizenConnector'

type TCodePromoCreateForm = {
  onCreated: (codePromo: TCodePromo) => void
}

export default function CodePromoCreateForm({ onCreated }: Readonly<TCodePromoCreateForm>): ReactElement {
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
  } = useForm<TCodePromoPost>({
    reValidateMode: 'onChange',
  })

  const isNoChange = useMemo(() => Object.keys(dirtyFields).length === 0, [dirtyFields])

  const onValidated = async (data: TCodePromoPost) => {
    const codePromo = await ApiArtizenBO.codePromo.post({
      code: data.code,
      duration: +data.duration,
      ...(data.absoluteDiscount ? { absoluteDiscount: +data.absoluteDiscount } : {}),
      ...(data.relativeDiscount ? { relativeDiscount: +data.relativeDiscount } : {}),
    })
    onCreated && onCreated(codePromo)
  }

  return (
    <form
      onSubmit={handleSubmit(onValidated, console.error)}
      style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 20 }}
    >
      <InputTextControl control={control} name="code" label="Code" error={errors.code} required />
      <Row style={{ alignItems: 'center' }}>
        <InputTextControl
          control={control}
          name="absoluteDiscount"
          label="Remise en €"
          error={errors.absoluteDiscount}
        />
        ou
        <InputTextControl
          control={control}
          name="relativeDiscount"
          label="Remise en %"
          error={errors.relativeDiscount}
        />
      </Row>
      <InputTextControl control={control} name="duration" label="Durée en mois" error={errors.duration} />
      <Button
        type="submit"
        disabled={isNoChange}
        style={{
          display: 'flex',
          alignSelf: 'center',
          backgroundColor: !isNoChange ? Theme.colors.blueArtizen : Theme.colors.blueGray,
          color: 'white',
          width: 200,
        }}
      >
        Créer
      </Button>
    </form>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  ${Theme.font.size.xlarge};
  font-weight: 700;
  border-bottom: 1px solid;
`
