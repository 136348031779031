export const artisanPages = {
  root: '/artisan/:id',
  profile: {
    name: '/artisan/:id/profile',
    navigate: (id: number) => `/artisan/${id}/profile`,
  },
  subscription: {
    name: '/artisan/:id/subscription',
    navigate: (id: number) => `/artisan/${id}/subscription`,
  },
  interventionList: {
    name: '/artisan/:id/interventionList',
    navigate: (id: number) => `/artisan/${id}/interventionList`,
  },
  quotationList: {
    name: '/artisan/:id/quotationList',
    navigate: (id: number) => `/artisan/${id}/quotationList`,
  },
  invoiceList: {
    name: '/artisan/:id/invoiceList',
    navigate: (id: number) => `/artisan/${id}/invoiceList`,
  },
  clientList: {
    name: '/artisan/:id/clientList',
    navigate: (id: number) => `/artisan/${id}/clientList`,
  },
  dashboard: {
    name: '/artisan/:id/dashboard',
    navigate: (id: number) => `/artisan/${id}/dashboard`,
  },
  facturation: {
    name: '/artisan/:id/facturation',
    navigate: (id: number) => `/artisan/${id}/facturation`,
  },
  extracts: {
    name: '/artisan/:id/extracts',
    navigate: (id: number) => `/artisan/${id}/extracts`,
  },
}
